import React, { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";

export const Clock = ({ onNote }) => {
  const [dateTime, setDateTime] = useState({
    time: new Date().toLocaleTimeString(),
    date: new Date().toLocaleDateString(),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().toLocaleTimeString();
      const currentDate = new Date().toLocaleDateString();
      setDateTime({ time: currentTime, date: currentDate });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  // const [badgeNumber, setBadgeNumber] = useState(5);

  return (
    <div className="d-flex col-md-6 flex-row gap-5 align-items-center justify-content-center">
      <h5 className="text-white pe-2">{dateTime.date}</h5>
      <h5 className="text-white digital-font pe-4">{dateTime.time}</h5>
    </div>
  );
};

// export const clientId= "42dd6f73-545d-4837-84c7-743c57914226"
// export const authority = `https://login.microsoftonline.com/bfecd333-7b34-4f0b-84ff-f069340788d7/tzmeaslamtsgmail.onmicrosoft.com`
// export const redirectUri = "/TMS/dashboard"

// liq
// export const clientId= "2c8a1b11-fb1a-4863-ae35-5ee3ddce192d"
// export const authority = `https://login.microsoftonline.com/a3ea8dc6-2f0a-470c-8c10-04fb621333ae/saml2`
// export const redirectUri = "/"
// liq

// airport
export const clientId= "33f8a749-6632-4a36-a329-9d4ae047eaf4"
export const authority = `https://login.microsoftonline.com/61903f2d-e804-491f-99d8-996637013ee5/saml2`
export const redirectUri = "/"
// airport

import { PieChart } from "../charts/NewChart/PieChart";
import { LineChart } from "../charts/NewChart/LineChart";
import { SecondBarchart } from "../charts/NewChart/SecondBarchart";
import { ZoneSection } from "./ZoneSection";
import { CustomBarChart } from "../charts/NewChart/CustomBarChart ";
import { AriaChart } from "../charts/NewChart/AriaChart";
import { PieChart2 } from "../charts/NewChart/PieChart2";
// import { LineChart2 } from "../charts/NewChart/LineChart2";
import React, { lazy, Suspense, useEffect, useState } from "react";

const Zones = lazy(() => import("./ZoneSection"));

const Section = ({
  allZone,
  currentStatusGraph,
  trolleyUtilizationGraph,
  trolleyDistributionGraphData,
  trolleyMaintenanceTrackerGraphData,
  selectTerminalId
}) => {

const [barstate,setbarstate]= useState(null)


useEffect(()=>{
  // alert('updated')
  console.log('updated' , barstate)
},[barstate])
  return (
    <section className="pb-5">
      <Suspense fallback={<h1 className="text-warning">Loading...</h1>}>
        <Zones allZone={allZone} currentStatusGraph={currentStatusGraph} />
      </Suspense>
      {/* <div className="col-md-12 rounded-2 ">
          <ZoneSection
            allZone={allZone}
            currentStatusGraph={currentStatusGraph}
          />
        </div> */}
      <div className="row pb-5">
        <div className="col-md-12">
          <div className="row mb-3">
            <div className="col-md-6 b">
              <p className="text-white">Current Status</p>
              <div
                className="w-100 border border-danger rounded-2"
                style={{
                  height: "93%",
                  background: "linear-gradient(-45deg, #0f3460, #1c1642)",
                }}
              >
                <div className="chart-box d-flex justify-content-center align-items-center h-100 w-100 pe-4">
                  <CustomBarChart currentStatusGraph={currentStatusGraph} setbarstate={setbarstate} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <p className="text-white ">
                Trolley Utilization Data (Last 24 Hour)
              </p>
              <div
                className="w-100 border border-danger rounded-2"
                style={{
                  height: "93%",
                  background: "linear-gradient(-45deg, #0f3460, #1c1642)",
                }}
              >
                <AriaChart 
                  selectTerminalId={selectTerminalId}
                  trolleyUtilizationGraph={trolleyUtilizationGraph}
                  allZone={allZone}
                  barChartIndex={barstate}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p className="text-white">Trolley Distribution Data</p>
              <div className="w-100 chart-box border border-danger rounded-2"
            //   style={{background: "linear-gradient(-45deg, #0f3460, #1c1642)",
            //   height: "93%",
            
            // }}
              >
                <PieChart
                  trolleyDistributionGraphData={trolleyDistributionGraphData}
                />
                {/* <PieChart2 trolleyDistributionGraphData={trolleyDistributionGraphData}/> */}
              </div>
            </div>
            <div className="col-md-6">
              <p className="text-white ">Trolley Maintenance Data</p>
              <div
                className="w-100 border border-danger rounded-2"
                style={{
                  height: "93%",
                  background: "linear-gradient(-45deg, #0f3460, #1c1642)",
                }}
              >
                <div className="chart-box d-flex justify-content-center align-items-center h-100 w-100 pe-4">
                  <SecondBarchart
                    trolleyMaintenanceTrackerGraphData={
                      trolleyMaintenanceTrackerGraphData
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export { Section };

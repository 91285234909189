import React, { useContext, useEffect, useState } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

//from praveen git check
import Staffs from "./components/table/ManageStaff/Staff";
import Invoices from "./components/table/ManageInvoices/Invoices";
import TimeSheet from "./components/table/ManageStaff/TimeSheet";
import AnnualLeaves from "./components/table/ManageStaff/AnnualLeaves";
import MedicalLeaves from "./components/table/ManageStaff/MedicalLeaves";
//from vikas rb git check

/// Pages
import Login from "./pages/Login";
import TimeSheetApprove2 from "./pages/TimeSheetApprove";
import { ThemeContext } from "../context/ThemeContext";
// import profile from "../../src/images.";

// import DeviceCreate from "./pages/DeviceCreate";
import ManageTariff from "./components/table/tariff/manageTariff";
import ManageTrolly from "./pages/ManageTrolly";
import ManageReader from "./pages/ManageReader";
import ManageZone from "./pages/ManageZone";
import { SubAdmin } from "./pages/SubAdmin";
import { NotificationReport } from "./pages/NotificationReport";
import { TrolleyBatches } from "./pages/TrolleyBatches";
import { TrolleyMaintenance } from "./pages/TrolleyMaintenance";
import ManageTerminals from "./pages/ManageTerminals";
import ServiceAvailabilityReport from "./pages/ServiceAvailability";
import Cookies from "js-cookie";

// import ManageBlutooth from "./pages/ManageBlutooth";
// import ManageVehicle from "./pages/ManageVehicle.js";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "/", component: Home },
    { url: "dashboard", component: Home },
    { url: "app-Tariff", component: ManageTariff },
    /// table
    { url: "app-staffs", component: Staffs },
    { url: "app-invoices", component: Invoices },
    { url: "app-timeSheet", component: TimeSheet },
    // { url: 'app-timeSheetApprove', component: TimeSheetApprove },
    { url: "app-annualLeaves", component: AnnualLeaves },
    { url: "app-medicalLeaves", component: MedicalLeaves },
    { url: "app-trolly", component: ManageTrolly },
    { url: "app-reader", component: ManageReader },
    { url: "app-terminals", component: ManageTerminals },
    { url: "app-zone", component: ManageZone },
    { url: "app-admin", component: SubAdmin },
    { url: "app-trolleyBatches", component: TrolleyBatches },
    { url: "app-trolleyMaintenance", component: TrolleyMaintenance },
    { url: "app-sar", component: ServiceAvailabilityReport },
    { url: "app-NotificationReport", component: NotificationReport },

    { url: "page-login", component: Login },

    { url: "page-timeSheet", component: TimeSheetApprove2 },
  ];

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);

  let path = window.location.pathname;
  // console.log('path:  index page ', path);
  // path = path.split("/");
  // console.log('path:  index page ', path);
  // path = path[path.length - 1];
  // console.log('path:  index page ', path);
  // console.log('path: index page ', path.split("-"));

  // let pagePath = path.split("-").includes("page");
  let pagePath = path.includes("page");
  console.log("pagePath: ", pagePath);
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        {/* <div className={`${userRole !== "5" ? "content-body" : ""}`}> */}
        <div
          className={`${userRole !== "5" && !pagePath ? "content-body" : ""}`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

export default Markup;

import React, { Suspense, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import useWebSocket, { ReadyState } from "react-use-websocket";
import axios from "axios";
import config from "../../../services/config";
import { Section } from "./components/Section";

// import IconWithBadge from './IconWithBadge'; // Adjust the path accordingly

import {  Badge } from "react-bootstrap";
import { ChatBox } from "../../layouts/ChatBox";
// import { AriaChart } from "./charts/NewChart/AriaChart";

const Home = ({ onNote }) => {
  // const WS_URL = 'ws://127.0.0.1:8000';
  const WS_URL = "wss://tms.liquidlab.in/ws";

  // const websocket= useWebSocket(WS_URL, {
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      console.log("data: ", data);
      setAllZone(data?.terminals[0].dashboardGraphData.Zones);
      setcurrentStatusGraph(
        data?.terminals[0].dashboardGraphData.currentStatusGraph
      );
      settrolleyUtilizationGraph(
        data?.terminals[0].dashboardGraphData.trolleyUtilizationGraph
      );
      settrolleyDistributionGraphData(
        data?.terminals[0].dashboardGraphData.trolleyDistributionGraphData
      );
      settrolleyMaintenanceTrackerGraphData(
        data?.terminals[0].dashboardGraphData.trolleyMaintenanceTrackerGraphData
      );
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true,
  });

  const { changeBackground } = useContext(ThemeContext);

  // const [dashboardData] = useState({});
  const [DashboardReady, setDashboardReady] = useState(false);
  const [allZone, setAllZone] = useState([]);
  const [zones, setZones] = useState([]);

  const [currentStatusGraph, setcurrentStatusGraph] = useState([]);

  const [trolleyUtilizationGraph, settrolleyUtilizationGraph] = useState([]);

  const [trolleyDistributionGraphData, settrolleyDistributionGraphData] =
    useState([]);
  const [
    trolleyMaintenanceTrackerGraphData,
    settrolleyMaintenanceTrackerGraphData,
  ] = useState([]);

  const [Notifications, setNotification] = useState([]);
  console.log("Notifications: ", Notifications);
  const [TerminalNotifications, setTerminalNotifications] = useState([]);
  console.log("TerminalNotifications.............:", TerminalNotifications);
  // console.log("TerminalNotifications: ", TerminalNotifications);
  // const [ZoneNotifications, setZoneNotification] = useState([]);
  // console.log("ZoneNotifications: ", ZoneNotifications);

  const [terminals, setTerminal] = useState([]);
  console.log("terminals: ", terminals);
  const [terminlaId, setTerminalId] = useState("");

  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
    getTerminals();
  }, []);

  function getTerminals() {
    axios({
      url: config.baseUrl + "getTerminals",
      method: "POST",
      headers: {
        token: sessionStorage.getItem("token"),
        "Content-Type": "application/json", // Moved to headers
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const terminalData = res?.data?.data;
          if (terminalData && terminalData?.length > 0) {
            const terminalIdName = terminalData?.map((item) => ({
              _id: item._id,
              terminalName: item?.terminalName,
            }));
            window.sessionStorage.setItem(
              "terminalIdName",
              JSON.stringify(terminalIdName)
            );
            setTerminal(terminalData);
          }
          setDashboardReady(true);
        } else if (res.status === 401) {
          alert(
            "You are unauthorized or your session is expired... please login again to continue"
          );
        }
      })
      .catch((err) => {
        if (err.response?.data?.status === 401) {
          // Handle unauthorized error
          console.error(err.response?.data?.status);
        } else {
          console.error("Error fetching terminals:", err);
        }
      });
  }

  function getdashboardAPI(_id) {
    axios({
      url: config.baseUrl + "getDashboardData",
      // method: "GET",
      method: "POST",
      "Content-Type": "application/json",
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: { terminalId: _id, zoneIdsForUtilization: zones },
    })
      .then((res) => {
        if (res.status === 200) {
          const zone = res?.data?.data?.Zones;
          const currentStatusGraph = res?.data?.data?.currentStatusGraph;
          const trolleyUtilizationGraph =
            res?.data?.data?.trolleyUtilizationGraph;
          const trolleyDistributionGraphData =
            res?.data?.data?.trolleyDistributionGraphData;
          const trolleyMaintenanceTrackerGraphData =
            res?.data?.data?.trolleyMaintenanceTrackerGraphData;
          // console.log("zone:", trolleyUtilizationGraph);
          // const dashboardData = res.data.data?.notifications;
          // setDashboardData(dashboardData);

          // if (zone.length > 0) {
          //   setCurrentZoneIdForGraph(zone[0]._id);
          setAllZone(zone);
          setcurrentStatusGraph(currentStatusGraph);
          settrolleyUtilizationGraph(trolleyUtilizationGraph);
          settrolleyDistributionGraphData(trolleyDistributionGraphData);
          settrolleyMaintenanceTrackerGraphData(
            trolleyMaintenanceTrackerGraphData
          );
          // setNotification(notifications);
          // }
        } else if (res.status === 201) {
        } else if (res.status === 401) {
          alert(
            "You are unauthorized or your session is expired... please login again to continue"
          );
        }
      })
      .catch((err) => {
        if (err.response?.data?.status === 401) {
        }
      });
  }

  const [activeTerminal, setActiveTerminal] = useState({ a: false, b: false });
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectTerminalId, setSelectTerminalId] = useState("");
  // const [defaultTerminalId,setDefaultTerminalId] = useState("660261f16140b9b11ca074ee");

  // useEffect(()=>{
  //   handleTerminalClick(defaultTerminalId)
  // },[])
  const handleTerminalClick = (Id) => {
    setSelectTerminalId(Id);
    const terminal = terminals?.filter((e) => e?._id === Id);
    setTerminalId(terminal[0]?._id);
    setSelectedTerminal(terminal[0]?.terminalName);
    getdashboardAPI(terminal[0]?._id);
    const idsArray = terminal[0]?.zones?.map((zone) => zone?._id);

    setZones(idsArray);
    if (terminal) {
      setSelectedTerminal(terminal[0]?.terminalName);
      terminal[0]?.terminalName === "Terminal 1"
        ? setActiveTerminal({ a: true, b: false })
        : setActiveTerminal({ a: false, b: true });
    } else {
      console.error("Terminal not found");
    }
  };

  const getCardStyle = (terminal) => ({
    cursor: "pointer",
    background:
      selectedTerminal === terminal
        ? "linear-gradient(-45deg, #2ecc71, #3498db)"
        : "linear-gradient(-45deg, #0f3460, #1c1642)",
    transition: "all 0.3s ease",
    // width: selectedTerminal === terminal ? "110%" : "100%",
    height: selectedTerminal === terminal ? "110%" : "100%",
    borderColor: "#ffff",
  });
  const getCardStyle1 = (terminal) => ({
    cursor: "pointer",
    background: selectedTerminal === terminal ? "#a6a6a6" : "#a6a6a6",
    transition: "all 0.3s ease",
    // width: selectedTerminal === terminal ? "110%" : "100%",
    height: selectedTerminal === terminal ? "110%" : "100%",
    borderColor: "#ffff",
  });

  const [toggle, setToggle] = useState("");

  const notification = (name, terminalId) => {
    // setTerminalNotifications(terminalId);
    setToggle(toggle === name ? "" : name);
  };
  const isMobileView = window.innerWidth < 700;

  return (
    <>
      <ChatBox
        style={{ backgroundColor: "red" }}
        onClick={() => notification("chatbox")}
        toggle={toggle}
        // Notifications1={Notifications1}
        // Notifications2={Notifications2}
        terminals={terminals}
        terminlaId={selectTerminalId}
      />

      <div className="overflow-x-hidden">
        <div className="col-xl-12">
          <>
            <div className={isMobileView ? "pt-2" : "pt-5"}>
              <div>
                <div className="row-12 d-flex">
                  {terminals?.map((e, index) => (
                    <div className="col-6 p-2 " key={index}>
                      {e.terminalName === "Terminal 2" ? (
                        <div
                          className={`card w-100 position-relative border-danger `}
                          // onClick={() => handleTerminalClick(e._id)}
                          style={getCardStyle1(e?.terminalName)}
                        >
                          <div className="card-body">
                            <h5 className="text-white">{e?.terminalName}</h5>
                            <h5 className="text-white">{e?.description}</h5>
                            <h6 className="card-subtitle mb-2 text-white">
                              {`Total Trolleys : ${e.totaltrolleys}`}
                            </h6>
                          </div>

                          <div
                            className="position-absolute left-100 d-flex justify-content-center align-items-center"
                            style={{
                              width: "4rem",
                              height: "5rem",
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <div
                              id="#terminal"
                              style={{
                                width: "3rem",
                                height: "3rem",
                                position: "relative",
                              }}
                              // onClick={() => notification('notification')}
                              onClick={() =>
                                notification("chatbox", terminlaId)
                              }
                            >
                              <i
                                className="fa-regular fa-bell"
                                style={{ fontSize: "1.7rem" }}
                              ></i>{" "}
                              <Badge
                                bg="danger"
                                text="white"
                                className="position-absolute text-white bg-danger d-flex align-items-center justify-content-center text-center"
                                style={{
                                  top: "0",
                                  left: "1rem",
                                  width: "0.3rem",
                                  height: "1.2rem",
                                }}
                              >
                                {e.notifications?.length}{" "}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`card w-100 position-relative border-danger`}
                          onClick={() => handleTerminalClick(e?._id)}
                          style={getCardStyle(e?.terminalName)}
                        >
                          <div className="card-body">
                            <h5 className="text-white">{e?.terminalName}</h5>
                            <h5 className="text-white">{e?.description}</h5>
                            <h6 className="card-subtitle mb-2 text-white">
                              {`Total Trolleys : ${e.totaltrolleys}`}
                            </h6>
                          </div>

                          <div
                            className="position-absolute left-100 d-flex justify-content-center align-items-center"
                            style={{
                              width: "4rem",
                              height: "5rem",
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <div
                              id="#terminal"
                              style={{
                                width: "3rem",
                                height: "3rem",
                                position: "relative",
                              }}
                              // onClick={() => notification('notification')}
                              onClick={() =>
                                notification("chatbox", terminlaId)
                              }
                            >
                              <i
                                className="fa-regular fa-bell"
                                style={{ fontSize: "1.7rem" }}
                              ></i>{" "}
                              <Badge
                                bg="danger"
                                text="white"
                                className="position-absolute text-white bg-danger d-flex align-items-center justify-content-center text-center "
                                style={{
                                  top: "0",
                                  left: "1rem",
                                  width: "0.3rem",
                                  height: "1.2rem",
                                }}
                              >
                                {e.notifications?.length}{" "}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* <Section /> */}
                <Suspense
                  fallback={<h1 className="text-warning">Loading...</h1>}
                >
                  <div className="row-12 ">
                    {activeTerminal.a && (
                      <div className="row-12">
                        <Section
                          selectTerminalId={selectTerminalId}
                          allZone={allZone}
                          currentStatusGraph={currentStatusGraph}
                          trolleyUtilizationGraph={trolleyUtilizationGraph}
                          trolleyDistributionGraphData={
                            trolleyDistributionGraphData
                          }
                          trolleyMaintenanceTrackerGraphData={
                            trolleyMaintenanceTrackerGraphData
                          }
                        />
                      </div>
                    )}

                    {activeTerminal.b && (
                      <div className="row-12 bg-secondary">
                        <Section
                          allZone={allZone}
                          currentStatusGraph={currentStatusGraph}
                          trolleyUtilizationGraph={trolleyUtilizationGraph}
                          trolleyDistributionGraphData={
                            trolleyDistributionGraphData
                          }
                          trolleyMaintenanceTrackerGraphData={
                            trolleyMaintenanceTrackerGraphData
                          }
                        />
                      </div>
                    )}
                  </div>
                </Suspense>
              </div>
            </div>
            {/* <Header/> */}
          </>

          {DashboardReady ? (
            ""
          ) : (
            <div class="loader_shadow" style={{ height: "100%" }}>
              <div class="loader_loader">
                <div class="loader_item loader_top"></div>
                <div class="loader_item loader_left"></div>
                <div class="loader_item loader_right"></div>
                <div class="loader_item loader_bottom"></div>
              </div>
            </div>
          )}
        </div>
        {/* // <div class="d-flex justify-content-center align-items-center" style={{ 'height': '100vh' }}>
					// 	<div class="loader_loader">
					// 		<div class="loader_item loader_top"></div>
					// 		<div class="loader_item loader_left"></div>
					// 		<div class="loader_item loader_right"></div>
					// 		<div class="loader_item loader_bottom"></div>
					// 	</div>
					// </div> */}
      </div>
    </>
  );
};
export default Home;

import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";

const Footer = () => {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);
  var d = new Date();
  return (
    <div
      className={`footer fixed-bottom bg-transparent text-center w-full ${
        userRole === "5" ? "pl-0" : "ml-5"
      }`}
      // style={{ height: "10vh" }}
    >
      {/* <div className="col-2"></div> */}
      <div
        className={` copyright ${
          userRole === "5" ? "pl-0" : "pl-5 ml-5"
        }`}
        style={{ backgroundColor: "#030414" ,
          paddingLeft: userRole === "5" ? "0" : "18rem"
        }}
      >
        {/* <p>
          Copyright © Designed &amp; Developed by{" "}
          <a href="http://liquidlab.in/" target="_blank" rel="noreferrer">
            Liquidlab Infosystems
          </a>{" "}
          {d.getFullYear()}
        </p> */}
        <p className="text-white">
          Kempegowda International Airport TMS dashboard {d.getFullYear()}
        </p>
        <p className="text-white">
          Designed &amp; Developed by{" "}
          <a href="http://liquidlab.in/" target="_blank" rel="noreferrer">
            Liquidlab Infosystems
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
// import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import axios from "axios";
import config from "../../services/config";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

const ManageTrolly = () => {
  const [showTrolly, setShowTrolly] = useState(true);
  const [showAll, setshowAll] = useState({ a: true, b: false, c: false });
  const [showTrollyForm, setShowTrollyForm] = useState(false);
  const [trolleyId, setTrollyId] = useState("");
  const [trollyNo, setTrollyNo] = useState("");
  const [trollyDescription, setTrollyDescription] = useState("");
  const [lastDetectedZone, setLastDetectedZone] = useState("");
  const [allTrolly, setAllTrolly] = useState("");
  console.log(allTrolly);
  const [userRole, setUserRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editedUser, setEditedUser] = useState({});
  useEffect(() => {
    getAllTrolley();
    // updateZoneDatas()
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);

  const columns = [
    {
      name: "Sl no",
      selector: (row, index) => index + 1,
      width: "80px", // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" }; // removed partial line here
      },
    },
    {
      name: "Trolley No",
      selector: (row) => row.trolleyNo,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Last Zone No",
      selector: (row) => row.lastDetectedZoneNo,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => {
        if (userRole === "0" || "1" || "4") {
          // Replace 'admin' with the role that allows editing
          return (
            <button
              className="edit-btn"
              onClick={() => {
                handleZoneEdit();
                handleEdit(row);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          );
        }
        return null; // Return null if the user doesn't have permission
      },
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => {
        if (userRole === "0") {
          // Replace 'admin' with the role that allows deletion
          return (
            <button className="delete-btn" onClick={() => handleDelete(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          );
        }
        return null; // Return null if the user doesn't have permission
      },
    },
  ];

  const handleEdit = (row) => {
    console.log("Editing row:", row);
    console.log(row);
    setSelectedUser(row);
    setEditedUser({ ...row });
  };
  const handleZoneEdit = () => {
    setshowAll({
      a: false,
      c: true,
    });
  };
  const cancelButton = () => {
    setshowAll({
      a: true,
      b: false,
    });
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        // fontSize: "18px",
        // fontWeight: "bold",
        // paddingLeft: '41px',
        marginRight: "-3px",
        color: "#f8857e",
        width: "180px",

        backgroundColor: "#fef3f2",
      },
    },
    cells: {
      style: {
        marginRight: "1px",
        width: "165px",
        textAlign: "center",
        // override the cell padding for data cells
      },
    },
  };

  const handleDelete = (row) => {
    console.log("Deleting row:", row);
    axios({
      url: config.baseUrl + "DeleteZone",
      method: "DELETE",
      "Content-Type": "application/json",
      data: row,
    })
      .then((res) => {
        if (res.status === 200) {
          alert(res.status.message);
        }
      })
      .catch((err) => {
        // console.log(err);
        // if (err.response?.data.status === 401) {
        //   alert("you are unauthorized.. please login to continue");
        //   console.log("vikas04");
        // }
      });
  };

  // const [loading,setLoading] = useState(true)
  const handleTrolly = () => {
    setshowAll({
      a: false,
      b: true,
    });
  };

  const handleCreateTrolly = () => {
    const trolleyOBJ = {
      trolleyId: trolleyId,
      // trollyNo: trollyNo,
      trolleyNo: trollyNo,
      description: trollyDescription,
      // lastDetectedZone:lastDetectedZone
    };
    createTrolley(trolleyOBJ);
  };

  function createTrolley(trolleyObj) {
    // let updateEmployee = {
    //     profilePic:profilePic,
    //     drivingLicence:drivingLicenceDocument
    // }

    axios({
      url: config.baseUrl + "createTrolley",
      method: "POST",
      "Content-Type": "application/json",
      // headers: {
      //     token: sessionStorage.getItem("token"),
      // },
      data: trolleyObj,
    })
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          alert(res.data.message);
          // console.log(res.data.message);
          // window.location.reload();
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
  }

  function getAllTrolley() {
    const terminalAccessObj = {
      terminalAccess: JSON.parse(sessionStorage.getItem("terminalAccess")),
    };
    // let updateEmployee = {
    //     profilePic:profilePic,
    //     drivingLicence:drivingLicenceDocument
    // }

    axios({
      url: config.baseUrl + "getAllTrolley",
      method: "POST",
      "Content-Type": "application/json",
      // headers: {
      //     token: sessionStorage.getItem("token"),
      // },
      data: terminalAccessObj,
    })
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          // alert(res.data.message)
          setAllTrolly(res.data.data);
          // console.log(res.data.message);
          // window.location.reload()
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
  }
  return (
    <div className="pt-5">
      {showAll.a && (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="parkoBlue">Current Trolleys</h3>
              <Button
                className=""
                onClick={handleTrolly}
                variant="outline-primary"
              >
                <i class="fa fa-plus"></i> Add New Trolley
              </Button>
            </div>
          </div>
        </div>
      )}

      {showAll.b && (
        <div className="col-12">
          <div className="card h-auto">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3">
                    <h2 className="parkoBlue" style={{ fontSize: "21px" }}>
                      Add Trolley
                    </h2>
                    <div>
                      <Button
                        onClick={cancelButton}
                        className="me-2"
                        style={{ marginRight: "10px" }}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>

                      {userRole === "0" && (
                        <Button
                          onClick={handleCreateTrolly}
                          className="me-2"
                          varient="outline-primary"
                        >
                          {/* {loading ? <Spinner animation="border" size="sm" /> : '  Create Operator'} */}
                          Create Trolley
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAll.c && (
        <div className="card">
          <div className="card-header">
            <h3 className="parkoBlue">Update Trolley </h3>
            <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3 ">
              <Button
                className="me-2"
                onClick={handleEdit}
                variant="outline-primary"
              >
                {" "}
                <i class=" me-1 fa fa-plus"></i>Update Trolley{" "}
              </Button>
              <Button
                onClick={cancelButton}
                className="me-2"
                style={{ marginRight: "10px" }}
                variant="outline-primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}

      {showAll.b && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Trolley No</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={trollyNo}
                      onChange={(e) => {
                        setTrollyNo(e.target.value);
                      }}
                      placeholder="Enter Trolley No"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Trolley Id</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={trolleyId}
                      onChange={(e) => {
                        setTrollyId(e.target.value);
                      }}
                      placeholder="Enter Trolley Id"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={trollyDescription}
                      onChange={(e) => {
                        setTrollyDescription(e.target.value);
                      }}
                      placeholder="Type Description Here"
                    ></input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showAll.c && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Trolley No</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.trolleyNo || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          trollyNo: e.target.value,
                        })
                      }
                      placeholder="Enter Trolle No"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.description || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          description: e.target.value,
                        })
                      }
                      placeholder="Type Description Here"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Last Detected Zone</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.lastDetectedZoneNo || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          lastDetectedZoneNo: e.target.value,
                        })
                      }
                      placeholder="Last Detected Zone"
                    ></input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showAll.a && (
        <div className="card">
          <div className="card-body">
            <DataTable
              columns={columns}
              data={allTrolly}
              customStyles={tableCustomStyles}
              pagination
              // expandableRowsComponent={expandableWinningPriceTable}
              // expandableRows
              // fixedHeader
              // selectableRowsHighlight
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageTrolly;

import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const SecondBarchart = ({ trolleyMaintenanceTrackerGraphData }) => {
  const Series = trolleyMaintenanceTrackerGraphData?.Series;
  const categories = trolleyMaintenanceTrackerGraphData?.categories;

  const getColor = (seriesName) => {
    switch (seriesName) {
      case "Corective Cleared Trolleys":
        return "#03a629"; // Green color
      case "Planned Cleared Trolleys":
        return "#a38800"; // Yellow color
      case "Corrective Due trolleys":
        return "#FFA500"; // Orange color
      case "Planned Due Trolleys":
        return "#FF5733"; // Red color
      default:
        return "#8884d8"; // Default color
    }
  };

  const transformedData = categories?.map((category, index) => {
    const obj = { name: category };
    Series?.forEach((series) => {
      obj[series.name] = series.data[index];
    });
    return obj;
  });

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#fff"
            transform="rotate(0)"
          >
            {payload.value}
          </text>
        </g>
      );
    }
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        background={{ fill: "rgba(100,150,150,0.4)" }}
        width={500}
        height={300}
        data={transformedData}
        margin={{
          top: 0,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          label={{ value: "", position: "insideBottom", offset: -50 }}
          tick={<CustomizedAxisTick />}
        />
        <YAxis
          label={{ value: "Trolleys", angle: -90, position: "insideLeft" }}
          tick={<CustomizedAxisTick />}
        />
        <Legend />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {Series?.map((series, index) => (
          <Bar
            key={index}
            dataKey={series.name}
            stackId="a"
            fill={getColor(series.name)}
            label={{ position: "top", fill: "#7d7d7d" }}
          >
            {series.name}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

import React, { useState, useEffect } from "react";

export const Notes = ({ terminals }) => {
  const [terminnalNnotification1, setTerminnalNnotification1] = useState([]);
  const [terminnalNnotification2, setTerminnalNnotification2] = useState([]);
  const [type, setType] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (terminals != null && terminals.length > 0) {
      const notifications1 = terminals[0]?.notifications || [];
      const notifications2 = terminals[1]?.notifications || [];
      setTerminnalNnotification1(notifications1);
      setTerminnalNnotification2(notifications2);
    }
  }, [terminals]);

  useEffect(() => {
    const globalMessage = [
      ...terminnalNnotification1,
      ...terminnalNnotification2,
    ];

    const messages = globalMessage.map((message) => {
      let newType = "";
      if (message.type === 1) {
        newType = "Trolley mismatch";
      }
      if (message.isDanger === true && newType === "") {
        newType =
          "Trolley count remained below the higher threshold for 20 minutes";
      }
      if (message.type === 2 && newType === "") {
        newType = "Trolley count dropped below the lower threshold";
      }
      if (message.type === 3 && newType === "") {
        newType = "Trolley count returned to normal levels";
      }
      return {
        type: newType,
        zoneName: message.zoneName,
        totalTrolleys: message.totalTrolleys,
      };
    });

    setMessages(messages);
  }, [terminnalNnotification1, terminnalNnotification2]);

  return (
    <div id="Notes">
      <div className="container">
        {messages.map((message, index) => (
          <div className="row justify-content-center px-2 py-1" key={index}>
            <div className="col-md-12">
              <h4>{` ${message.zoneName}`}</h4>
              <h4>{`totalTrolleys: ${message.totalTrolleys}`}</h4>
              <p
                style={{
                  color:
                    message.type === "Trolley count returned to normal levels"
                      ? "green"
                      : message.type ===
                        "Trolley count remained below the higher threshold for 20 minutes"
                      ? "red"
                      : message.type ===
                        "Trolley count dropped below the lower threshold"
                      ? "orange"
                      : message.type === "Trolley mismatch"
                      ? "blue"
                      : "",
                }}
              >{` ${message.type}`}</p>
              <hr style={{ color: "black" }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

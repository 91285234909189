import axios from "axios";
import React, { PureComponent, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  Tooltip,
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import config from "../../../../../services/config";

export const AriaChart = ({
  trolleyUtilizationGraph,
  allZone,
  selectTerminalId,
  barChartIndex,
}) => {
  useEffect(() => {
    console.log("runing");
    allZone.map((zone, index) => {
      if (index === barChartIndex) {
        const updatedSelectedZones = [
          // ...selectedZones,
          { name: zone?.zoneName, id: zone?._id },
        ];

        setSelectedZones(updatedSelectedZones);
        const zoneSelectedIds = updatedSelectedZones?.map((zone) => zone.id);
        setSelectZoneIdes(zoneSelectedIds);

        // Send selected zone IDs to the server
        sendZoneIdsToServer(zoneSelectedIds);
      }
    });
  }, [barChartIndex]);

  const [selectedZoneIdes, setSelectZoneIdes] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [newAriaChartData, SetnewAriaChartData] = useState({});

  const handleZoneClick = (zoneName, zoneId) => {
    const selectedIndex = selectedZones?.findIndex(
      (zone) => zone?.name === zoneName
    );

    if (selectedIndex === -1) {
      // Zone not yet selected, add it to selectedZones
      const updatedSelectedZones = [
        ...selectedZones,
        { name: zoneName, id: zoneId },
      ];
      setSelectedZones(updatedSelectedZones);

      const zoneSelectedIds = updatedSelectedZones?.map((zone) => zone?.id);
      setSelectZoneIdes(zoneSelectedIds);

      // Send selected zone IDs to the server
      sendZoneIdsToServer(zoneSelectedIds);
    } else {
      // Zone already selected, remove it from selectedZones
      const updatedZones = [...selectedZones];
      updatedZones.splice(selectedIndex, 1);
      setSelectedZones(updatedZones);

      const zoneSelectedIds = updatedZones?.map((zone) => zone?.id);
      setSelectZoneIdes(zoneSelectedIds);

      // Send updated selected zone IDs to the server
      sendZoneIdsToServer(zoneSelectedIds);
    }
  };

  const sendZoneIdsToServer = (zoneIds) => {
    axios({
      url: config.baseUrl + "getDashboardData",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        terminalId: selectTerminalId,
        zoneIdsForUtilization: zoneIds, // Sending array of selected or updated selected zone IDs
      },
    })
      .then((response) => {
        console.log("ZoneData: ", response.data);
        SetnewAriaChartData(response.data.data.trolleyUtilizationGraph[0]);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data: ", error);
      });
  };

  const seriesData = newAriaChartData?.series ? newAriaChartData?.series : [];
  const categories = newAriaChartData?.categories
    ? newAriaChartData?.categories
    : [];

  const data = [
    ["Time", ...seriesData?.map((series) => series.name)],
    ...categories?.map((category, index) => [
      category,
      ...seriesData?.map((series) => series.data[index]),
    ]),
  ];

  const transformedData = data.slice(1)?.map(([name, Trolleys]) => ({
    name,
    Trolleys,
    pv: Math.floor(Math.random() * 5000), // Example value for pv (adjust as needed)
    amt: Math.floor(Math.random() * 5000), // Example value for amt (adjust as needed)
  }));

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#C7C7C7"
            transform="rotate(-35)"
          >
            {payload.value}
          </text>
        </g>
      );
    }
  }

  class CustomTooltip extends PureComponent {
    render() {
      const { active, payload } = this.props;
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p
              style={{
                color: "red",
                backgroundColor: "#C7C7C7",
                padding: "7px",
              }}
            >
              {" "}
              {`${payload[0]?.payload.name}`}
              <br />
              {`${payload[0]?.name} : ${payload[0]?.value}`}{" "}
            </p>
          </div>
        );
      }

      return null;
    }
  }

  //   const handleZoneClick = (zoneName,zoneId)=>{
  //     console.log('zoneId: ', zoneId);
  //     setSelectZone(zoneName)
  //  axios({
  //       url: config.baseUrl + "getDashboardData",
  //       method: "GET",
  //       // method: "POST",
  //       "Content-Type": "application/json",
  //       data: {
  //         zoneIdsForUtilization: [zoneId]
  //       },
  //     }).then((ZoneData)=>{
  //       console.log('ZoneData_____: ', ZoneData);
  //       console.log('ZoneData_____: : ' );
  //     })
  //   }
  // const handleZoneClick = (zoneName, zoneId) => {
  //   console.log('zoneId111: ', zoneId);
  //   console.log('zoneName111: ', zoneName);

  //   const selectedIndex = selectedZones.findIndex(
  //     (zone) => zone.name === zoneName
  //   );
  //   if (selectedIndex === -1) {
  //     // Zone not yet selected, add it to selectedZones
  //     setSelectedZones([...selectedZones, { name: zoneName, id: zoneId }]);
  //     const zoneSelectedIdes=selectedZones.map((e) => {
  //       return e.id
  //     });
  //     setSelectZoneIdes(zoneSelectedIdes);
  //     console.log('zoneSelectedIdes: ', zoneSelectedIdes);

  //     axios({
  //       url: config.baseUrl + "getDashboardData",
  //       // method: "GET",
  //       method: "POST",
  //       "Content-Type": "application/json",
  //       data: {terminalId: selectTerminalId,
  //         // zoneIdsForUtilization: selectedZoneIdes,
  //         zoneIdsForUtilization: zoneId,
  //       },
  //     }).then((ZoneData) => {
  //       console.log("ZoneData_____: ", ZoneData);
  //       console.log("ZoneData_____: : ");
  //     });
  //   } else {
  //     // Zone already selected, remove it from selectedZones
  //     const updatedZones = [...selectedZones];
  //     updatedZones.splice(selectedIndex, 1);
  //     setSelectedZones(updatedZones);
  //   }
  // };

  // const handleZoneClickAll = () => {
  //   const allZoneNames = allZone.map((zone) => zone._id);
  //   setSelectedZones(allZone.map((zone) => ({ name: zone._id })));
  //   console.log('allZoneNames: ', allZoneNames);
  //   handleZoneClick()
  //   setSelectZoneIdes(allZoneNames);
  // };

  return (
    <ResponsiveContainer style={{ width: "5rem" }} height={300}>
      {/* <div style={{maxWidth:"10rem"}}> */}
      <Dropdown className="m-2 d-flex justify-content-end">
  <Dropdown.Toggle
    variant="success"
    id="dropdown-basic"
    style={{
      backgroundColor: "#030414",
      borderColor: "#FFFFFF",
      border: "1rem",
      // Set a fixed width for the dropdown button
      width: "15rem", // Adjust the width as needed
      overflow:"auto",
      padding:".5rem"
    }}
  >
    {/* Display selected zones */}
    {selectedZones.length > 0
      ? selectedZones.map((zone, index) => (
          <span key={index}>
            {zone.name}
            {/* Add comma if not the last selected zone */}
            {index !== selectedZones.length - 1 && ", "}
          </span>
        ))
      : "Select Zone"}
  </Dropdown.Toggle>
  {/* Dropdown Menu */}
  <Dropdown.Menu
    // Apply styles for vertical scrolling
    style={{ overflowY: "auto", maxHeight: "20rem", width: "220px" }}
  >
    {/* Map over all zones */}
    {allZone.map((e, index) => (
      <Dropdown.Item
        key={index}
        href={`#/action-${index + 1}`}
        onClick={() => handleZoneClick(e.zoneName, e._id)}
      >
        {/* Display checkbox and zone name */}
        <input
          type="checkbox"
          readOnly
          checked={selectedZones.some((zone) => zone.name === e.zoneName)}
        />
        {e.zoneName}
      </Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>

      {/* </div> */}

      <AreaChart
        width={500}
        height={350}
        data={transformedData}
        margin={{ top: 30, right: 100, left: 10, bottom: 30 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="50%" stopColor="green" stopOpacity={0.8} />
            <stop offset="75%" stopColor="orange" stopOpacity={0.8} />
            <stop offset="100%" stopColor="red" stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          height={60}
          interval={2}
          tick={<CustomizedAxisTick />}
          label={{
            value: "Time",
            position: "insideBottom",
            fill: "#C7C7C7ff",
            offset: -20,
          }}
        />
        {/* <YAxis /> */}
        <YAxis
          label={{
            value: "Trolleys Count",
            angle: -90,
            fill: "#C7C7C7",
            position: "outsideLeft",
          }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="Trolleys"
          stroke="yellow"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        {/* <Area
          type="monotone"
          dataKey="pv"
          stroke="#82ca9d"
          fillOpacity={1}
          fill="url(#colorPv)"
        /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

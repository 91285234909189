import React, { useEffect, useState } from "react";

export const Chat = ({ terminals, terminlaId }) => {
    console.log('terminalsww: ', terminals);

//   console.log("terminlaIdiiii: ", terminlaId);
  const [terminalData, setTerminalData] = useState([]);

  const [type, setType] = useState("");

  useEffect(() => {
    if (terminals != null && terminals.length > 0) {
      const selectedTerminal = terminals.find(
        (terminal) => terminal._id === terminlaId
      );
      console.log("selectedTerminal: ", selectedTerminal);
      if (selectedTerminal) {
        setTerminalData(selectedTerminal.notifications || []);
      } else {
        setTerminalData([]);
      }
    } else {
      setTerminalData([]);
    }
  }, [terminals, terminlaId]);
  console.log("terminals: ", terminals);

  useEffect(() => {
    let newType = "";
    for (const message of terminalData) {
      if (message.type === 1) {
        newType = "Trolley mismatch";
        break;
      } else if (message.type === 2 && message.isDanger === true) {
        newType =
          "Trolley count remained below the higher threshold for 20 minutes";
        break;
      } else if (message.type === 2) {
        newType = "Trolley count dropped below the lower threshold";
        break;
      } else if (message.type === 3) {
        newType = "Trolley count returned to normal levels";
        break;
      }
    }
    setType(newType);
  }, [terminalData]);
  return (
    <div id="Notes">
      <div className="container">
        {terminalData.map((notification, index) => (
          <div className="row justify-content-center px-2 py-1" key={index}>
            <div className="col-md-12">
              <h4>{` ${notification.zoneName}`}</h4>
              <h4>{`totalTrolleys: ${notification.totalTrolleys}`}</h4>
              <p className="text-black">{`Type: ${type}`}</p>
              <p className="text-black">{`Message Type: ${notification.type}`}</p>
              <p className="text-black">{`Danger: ${notification.isDanger}`}</p>
              <hr style={{ color: "black" }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

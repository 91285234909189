import React, { PureComponent, useEffect } from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
// const barColors = ["#1f77b4", "#ff7f0e", "#2ca02c"]
// const barColors = ["red", "orange", "green"];
export const CustomBarChart = ({ currentStatusGraph,setbarstate }) => {
  const Series = currentStatusGraph?.Series;
  let categories = currentStatusGraph?.categories;
  let status = currentStatusGraph?.status;



  // Transform data into the required format
  const data = categories?.map((category, index) => ({
    name: category,
    CurrentTrolleyCount: Series[0]?.data[index],
  }));
  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={1}
            textAnchor="end"
            fill="#C7C7C7"
            transform="rotate(-35)"
          >
            {payload.value}
          </text>
        </g>
      );
    }
  }
  const UtilizationData = (zone)=>{

    setbarstate(zone)
    
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 0, right: 0, left: 21, bottom: 27 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          // dataKey="name" interval={0} angle={-10} minTickGap={10} fill="#ffffff"
          dataKey="name"
          height={60}
          interval={0}
          tick={<CustomizedAxisTick />}
          label={{
            value: "",
            position: "insideBottom",
            fill: "#C7C7C7",
            offset: -20,
          }}
        />
        <YAxis
          label={{
            value: "Current Trolley Count",
            angle: -90,
            fill: "#C7C7C7",
            position: "outsideLeft",
          }}
        />
        <Tooltip />
        <Legend
          verticalAlign="top"
          align="right"
          wrapperStyle={{ paddingTop: ".5rem" }}
          payload={[
            { value: "low", type: "square", color: "red" }, // Example Legend 2
            { value: "md", type: "square", color: "orange" }, // Example Legend 3
            { value: "hig", type: "square", color: "green" }, // Example Legend 3
          ]}
        />
        <Bar
          dataKey="CurrentTrolleyCount"
          fill="#ffaa00"
          background={{ fill: "rgba(100,150,150,0.4)" }}
          stackId="a"

        >
          {status?.map((status, index) => (
            <Cell
            key={`cell-${index}`}
            fill={status}
            onClick={() => UtilizationData(index)}
          />
          
            // <text
            //   x={index * 30}
            //   y={entry.Baggage + 10}
            //   textAnchor="middle"
            //   dominantBaseline="middle"
            // >
            //   {entry.name}
            // </text>
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;

import React from "react";
import { Chart } from "react-google-charts";

export const PieChart = ({ trolleyDistributionGraphData }) => {
  const Series = trolleyDistributionGraphData?.series;
  const categories = trolleyDistributionGraphData?.labels;
  const activeTrolleySubParts = [20, 30, 40, 60]; // Example subparts data for "Active Trolleys"

  // Define colors for each subpart including hover color for "Active Trolleys - Part 1"
  const categoryColors = [
    "#064bcc", // Dark blue color for "Active Trolleys - Part 1"
    "#FF5733",
    "#FFA500",
  ];

  const chartData = [["Task", "Hours per Day"]];
  categories?.forEach((category, index) => {
    if (category === "Active Trolleys") {
      // Add the main section with multiple subparts
      activeTrolleySubParts.forEach((value, subIndex) => {
        chartData.push([`Active Trolleys - Part ${subIndex + 1}`, value]);
      });
    } else {
      chartData.push([category, Series[index]]);
    }
  });

  const options = {
    is3D: true,
    slices: categories?.map((category, index) => ({
      color:
        category === "Active Trolleys"
          ? categoryColors[index]
          : categoryColors[category],
      hoverBorderColor: index === 0 ? "black" : "", // Change border color for "Active Trolleys - Part 1" on hover
      hoverOffset: index === 0 ? 5 : 0, // Increase offset for "Active Trolleys - Part 1" on hover
    })),
    fontSize: 14,
    legend: {
      textStyle: { fontSize: 12,color: "#ffffff" }, 
    },
    pieSliceTextStyle: {
      color: "#f0f1f5",
      fontSize: 16,
      textStyle: {
        color: "#ffffff", // Set color to white
      },
    },
    backgroundColor: "#050e26",
    // backgroundColor: "#050e26",
    // backgroundColor: {
    //   fill: "linear-gradient(to right, #050e26, #3a6186)", // Define your gradient colors here
    //   angle: 45, // Gradient angle
    // },
    
  };

  return (
    // <div style={{ borderRadius: "100%",}}>

    <Chart

      chartType="PieChart"
      data={chartData}
      options={options}
      width={"100%"}
      height={"500px"}
    />
    // </div>
  );
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Chat } from "../components/Dashboard/chatBox/Chat";
import { Notes } from "../components/Dashboard/chatBox/Notes";
import { Alerts } from "../components/Dashboard/chatBox/Alerts";

// Other imports...

export const ChatBox = ({
  onClick,
  toggle,
  // Notifications1,
  // Notifications2,
  terminals,
  terminlaId
}) => {
  console.log('terminlaIdchatBox: ', terminlaId);
  const [activeTab, setActiveTab] = useState("terminal");

  const dataToggle = [
    { id: "terminal", name: "Terminal" },
    { id: "global", name: "Global" },
    // { id: "zone", name: "Zone" },
  ];

  useEffect(() => {
    // Set the active tab based on the hash in the URL
    console.log("window.location.hash:", window.location.hash);
    const hash = window.location.hash.slice(1);
    if (dataToggle.some((data) => data.id === hash)) {
      setActiveTab(hash);
    }
  }, []); // Run only once on component mount

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div
      className={`chatbox ${
        toggle === "chatbox" ? "active" : ""
      } overflow-auto `}
    >
      <div className="chatbox-close" onClick={() => onClick()}></div>
      <div className="custom-tab-1">
        <ul className="nav nav-tabs">
          {dataToggle.map((data) => (
            <li className="nav-item" key={data.id}>
              <Link
                className={`nav-link ${activeTab === data.id ? "active" : ""}`}
                to={`#${data.id}`}
                onClick={() => handleTabClick(data.id)}
              >
                {data.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* Render your tab content based on the activeTab state */}
        <div className="tab-content">
          {/* {activeTab === "zone" && (
            <Chat
              // Notifications1={Notifications1}
              // Notifications2={Notifications2}
              terminals={terminals}

            />
          )} */}
          {activeTab === "global" && (
            <Notes
              // Notifications1={Notifications1}
              terminals={terminals}
              // Notifications2={Notifications2}
            />
          )}
          {activeTab === "terminal" && (
            <Alerts
            terminals={terminals}
            terminlaId={terminlaId}

              // Notifications1={Notifications1}
              // Notifications2={Notifications2}
            />
          )}
        </div>
      </div>
    </div>
  );
};
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
// import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import axios from "axios";
import config from "../../services/config";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./manageZone.css";
import Cookies from "js-cookie";

const ManageName = () => {
  // Implement functionality for deleting a row
  const [showZone, setShowZone] = useState(true);
  const [showZoneForm, setZoneShow] = useState(false);
  const [showAll, setshowAll] = useState({ a: true, b: false, c: false });

  const [zoneName, setZoneName] = useState("");
  const [zoneNo, setZoneNo] = useState("");
  const [zoneDescription, setZoneDescription] = useState("");
  const [Availeblespace, setAvaileblespace] = useState("");
  const [AvailibleTrolly, setAvailibleTrolly] = useState("");
  const [LowThreshold, setLowThreshold] = useState("");
  const [HighThreshold, setHighThreshold] = useState("");
  const [Status, setStatus] = useState("");
  const [allZone, setAllZone] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editedUser, setEditedUser] = useState({});

  useEffect(() => {
    getAllZone();
    // updateZoneDatas()
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);

  const columns = [
    {
      name: "Sl no",
      selector: (row, index) => index + 1,
      width: "80px", // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" }; // removed partial line here
      },
    },
    {
      name: "Name",
      selector: (row) => row.zoneName,
    },
    {
      name: "No",
      selector: (row) => row.zoneNo,
    },
    /*{
      name: "Status",
      selector: (row) => row.Status,
      cell: (row) => {
        let statusColor = "";

        // Assign colors based on different statuses
        switch (row.Status) {
          case "low":
            statusColor = "red";
            break;
          case "md":
            statusColor = "orange";
            break;
          case "hig":
            statusColor = "green";
            break;
          default:
            statusColor = "black"; // Default color if status doesn't match
        }

        return (
          <div
            className="w-50 rounded text-white"
            style={{ backgroundColor: statusColor }}
          >
            {row.Status}
          </div>
        );
      },
    },*/
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Availeble space",
      selector: (row) => row.Availeblespace,
    },
    {
      name: "Availible Trolly",
      selector: (row) => row.AvailibleTrolly,
    },
    {
      name: "Low Threshold",
      selector: (row) => row.LowThreshold,
    },
    {
      name: "High Threshold",
      selector: (row) => row.HighThreshold,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => {
        if (userRole === "0") {
          // Replace 'admin' with the role that allows editing
          return (
            <button
              className="edit-btn"
              onClick={() => {
                handleZoneEdit();
                handleEdit(row);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          );
        }
        return null; // Return null if the user doesn't have permission
      },
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => {
        if (userRole === "0") {
          // Replace 'admin' with the role that allows deletion
          return (
            <button className="delete-btn" onClick={() => handleDelete(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          );
        }
        return null; // Return null if the user doesn't have permission
      },
    },
  ];

  const handleEdit = (row) => {
    console.log("Editing row:", row);
    console.log(row);
    setSelectedUser(row);
    setEditedUser({ ...row });
  };

  const UpdateZone = () => {
    const EditedZoneData = editedUser;
    axios({
      url: config.baseUrl + "EditedZoneData",
      method: "PUT",
      "Content-Type": "application/json",
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: EditedZoneData,
    })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res, ":res____________aaaaaaa---------");
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          console.log("vikas04");
        }
      });
  };

  const handleDelete = (row) => {
    console.log("Deleting row:", row);
    axios({
      url: config.baseUrl + "DeleteZone",
      method: "DELETE",
      "Content-Type": "application/json",
      data: row,
    })
      .then((res) => {
        if (res.status === 200) {
          alert(res.status.message);
        }
      })
      .catch((err) => {
        // console.log(err);
        // if (err.response?.data.status === 401) {
        //   alert("you are unauthorized.. please login to continue");
        //   console.log("vikas04");
        // }
      });
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        marginRight: "-3px",
        color: "#f8857e",
        width: "180px",

        backgroundColor: "#fef3f2",
      },
    },
    cells: {
      style: {
        marginRight: "1px",
        width: "165px",
        textAlign: "center",
        // override the cell padding for data cells
      },
    },
  };

  const handleZone = () => {
    setshowAll({
      a: false,
      b: true,
    });
  };

  const handleZoneEdit = () => {
    setshowAll({
      a: false,
      c: true,
    });
  };
  const cancelButton = () => {
    setshowAll({
      a: true,
      b: false,
    });
  };

  const handleCreateZone = () => {
    const ZoneOBJ = {
      zoneName: zoneName,
      zoneNo: zoneNo,
      description: zoneDescription,
      Availeblespace: Availeblespace,
      AvailibleTrolly: AvailibleTrolly,
      LowThreshold: LowThreshold,
      HighThreshold: HighThreshold,
      Status: Status,
    };
    createZone(ZoneOBJ);
  };

  function createZone(zoneObj) {
    axios({
      url: config.baseUrl + "createZone",
      method: "POST",
      "Content-Type": "application/json",
      // headers: {
      //     token: sessionStorage.getItem("token"),
      // },
      data: zoneObj,
    })
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          alert(res.data.message);
          // console.log(res.data.message);
          window.location.reload();
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
  }

  function getAllZone() {
    console.log(sessionStorage.getItem("terminalAccess"));

    const terminalAccessObj={
      terminalAccess:JSON.parse(sessionStorage.getItem("terminalAccess"))
    }
    axios({
      url: config.baseUrl + "getAllZone",
      method: "POST",
      "Content-Type": "application/json",
      // headers: {
      //     token: sessionStorage.getItem("token"),
      // },
      data: terminalAccessObj,
    })
      .then((res) => {
        console.log("rex:", res);
        if (res.status === 200) {
          // alert(res.data.message)
          setAllZone(res.data.data);
          // updateZoneDatas()
          // console.log(res.data.message);
          // window.location.reload()
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
    // updateZoneDatas()
  }

  // function updateZoneDatas() {
  //   axios({
  //     url: config.baseUrl + "updateZoneStatus",
  //     method: "PUT",
  //     "Content-Type": "application/json",
  //     data: allZone,
  //   })
  //     .then((res) => {
  //       console.log("rex:", res);
  //       if (res.status === 200) {
  //       } else if (res.status === 201) {
  //       } else if (res.status === 401) {
  //         alert("you are unauthorized.. please login to continue");
  //         window.location = "/page-login";
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response?.data.status === 401) {
  //         alert("you are unauthorized.. please login to continue");
  //       }
  //     });
  // }

  return (
    <div className="pt-5">
      {showAll.a && (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="parkoBlue">Current Zones</h3>
              <Button
                className=""
                onClick={handleZone}
                variant="outline-primary"
              >
                <i class="fa fa-plus"></i> Add New Zone
              </Button>
            </div>
          </div>
        </div>
      )}

      {showAll.b && (
        <div className="col-12">
          <div className="card h-auto">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3">
                    <h2 className="parkoBlue" style={{ fontSize: "21px" }}>
                      Add Zone
                    </h2>
                    <div>
                      <Button
                        onClick={cancelButton}
                        className="me-2"
                        style={{ marginRight: "10px" }}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>

                      {userRole === "0" && (
                        <Button
                          onClick={handleCreateZone}
                          className="me-2"
                          varient="outline-primary"
                        >
                          {/* {loading ? <Spinner animation="border" size="sm" /> : '  Create Operator'} */}
                          Create Zone
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAll.c && (
        <div className="card">
          <div className="card-header">
            <h3 className="parkoBlue">Update Branch </h3>
            <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3 ">
              <Button
                className="me-2"
                onClick={UpdateZone}
                variant="outline-primary"
              >
                {" "}
                <i class=" me-1 fa fa-plus"></i>Update Branch{" "}
              </Button>
              <Button
                onClick={cancelButton}
                className="me-2"
                style={{ marginRight: "10px" }}
                variant="outline-primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}

      {showAll.b && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Zone Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={zoneName}
                      onChange={(e) => {
                        setZoneName(e.target.value);
                      }}
                      placeholder="Enter Zone Name"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Zone No</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={zoneNo}
                      onChange={(e) => {
                        setZoneNo(e.target.value);
                      }}
                      placeholder="Enter Zone No"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={zoneDescription}
                      onChange={(e) => {
                        setZoneDescription(e.target.value);
                      }}
                      placeholder="Type Description Here"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Availeble space</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={Availeblespace}
                      onChange={(e) => {
                        setAvaileblespace(e.target.value);
                      }}
                      placeholder="Type Availeblespace"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Availible Trolly</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={AvailibleTrolly}
                      onChange={(e) => {
                        setAvailibleTrolly(e.target.value);
                      }}
                      placeholder="Type AvailibleTrolly"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Low Threshold</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={LowThreshold}
                      onChange={(e) => {
                        setLowThreshold(e.target.value);
                      }}
                      placeholder="Type LowThreshold"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>High Threshold</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={HighThreshold}
                      onChange={(e) => {
                        setHighThreshold(e.target.value);
                      }}
                      placeholder="Type HighThreshold"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <input
                      type="text"
                      className="form-control"
                      value={Status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      hidden
                    ></input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showAll.c && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Zone Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.zoneName || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          zoneName: e.target.value,
                        })
                      }
                      placeholder="Enter Zone Name"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Zone No</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.zoneNo || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          zoneNo: e.target.value,
                        })
                      }
                      placeholder="Enter Zone No"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.zoneDescription || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          zoneDescription: e.target.value,
                        })
                      }
                      placeholder="Type Description Here"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Availeble space</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.Availeblespace || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          Availeblespace: e.target.value,
                        })
                      }
                      placeholder="Type Availeblespace"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Availible Trolly</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.AvailibleTrolly || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          AvailibleTrolly: e.target.value,
                        })
                      }
                      placeholder="Type AvailibleTrolly"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Low Threshold</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.LowThreshold || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          LowThreshold: e.target.value,
                        })
                      }
                      placeholder="Type LowThreshold"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>High Threshold</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.HighThreshold || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          HighThreshold: e.target.value,
                        })
                      }
                      placeholder="Type HighThreshold"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.Status || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          Status: e.target.value,
                        })
                      }
                      hidden
                    ></input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showAll.a && (
        <div className="card">
          <div className="card-body">
            <DataTable
              columns={columns}
              data={allZone}
              customStyles={tableCustomStyles}
              pagination
              // expandableRowsComponent={expandableWinningPriceTable}
              // expandableRows
              // fixedHeader
              // selectableRowsHighlight
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageName;

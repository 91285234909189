import React from "react";
import { useState, useRef } from "react";
import Select from "react-select";
import { Button, Spinner, Badge } from "react-bootstrap";
import axios from "axios";
import config from "../../services/config";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "./manageTerminals.css";
import Cookies from "js-cookie";

const ManageTerminals = () => {
  // Create Terminal Section Variables
  const [terminalName, setTerminalName] = useState("");
  const [terminalDescription, setTerminalDescription] = useState("");
  const [terminalRangeStart, setTerminalRangeStart] = useState("");
  const [terminalRangeEnd, setTerminalRangeEnd] = useState("");
  //const[trolleyNo,setTrolleyNo] = useState("");

  const [userRole, setUserRole] = useState(null);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [editedTerminal, setEditedTerminal] = useState({});

  // Main Screen Variables
  // const [showMainScreenTable, setShowMainScreenTable] = useState(true);
  // const [showCreateTerminalSection, setShowCreateTerminalSection] = useState(false);
  // const [showUpdateTerminalSection, setShowUpdateTerminalSection] = useState(false);
  const [showAll, setshowAll] = useState({ a: true, b: false, c: false });

  //API variables
  const [allTerminals, setAllTerminals] = useState([]);

  //Data Table
  const terminalColumns = [
    //terminalName, description, trolleyRange (Starting and Ending), trolleyNo
    {
      name: "Terminal Name",
      selector: (row) => row.terminalName,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Trolley ID Start",
      selector: (row) => row.trolleysNoRange?.starting,
    },
    {
      name: "Trolley ID End",
      selector: (row) => row.trolleysNoRange?.ending,
    },
    // {
    //   name: trolleyNo,
    //   selector: (row) => row.trolleyNo,
    // },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          className="edit-btn"
          onClick={() => {
            handleTerminalEdit(row);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button
          className="delete-btn"
          onClick={() => handleDeleteTerminal(row)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      ),
    },
  ];

  const tableCustomStyles = {
    headCells: {
      style: {
        // fontSize: "18px",
        // fontWeight: "bold",
        // paddingLeft: '41px',
        marginRight: "-3px",
        color: "#f8857e",
        width: "180px",

        backgroundColor: "#fef3f2",
      },
    },
    cells: {
      style: {
        marginRight: "1px",
        width: "165px",
        textAlign: "center",
        // override the cell padding for data cells
      },
    },
  };

  const handleTerminals = () => {
    setshowAll({
      a: false,
      b: true,
    });
  };

  const handleTerminalEdit = (row) => {
    setshowAll({
      a: false,
      c: true,
    });
    console.log("Editing row:", row);
    console.log(row);
    setSelectedTerminal(row);
    setEditedTerminal({ ...row });
  };

  // button functinos
  const cancelButton = () => {
    setshowAll({
      a: true,
      b: false,
    });
  };

  useEffect(() => {
    getTerminals();
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);

  function getTerminals() {
    const terminalAccessObj = {
      terminalAccess: JSON.parse(sessionStorage.getItem("terminalAccess")),
    };
    axios({
      url: config.baseUrl + "getTerminals",
      method: "POST",
      "Content-Type": "application/json",
      data: terminalAccessObj,
    })
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          // alert(res.data.message)
          setAllTerminals(res.data.data);
          // console.log(res.data.message);
          // window.location.reload()
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
  }

  const handleCreateTerminal = () => {
    const terminalObj = {
      terminalName: terminalName,
      description: terminalDescription,
      terminalRangeStart: terminalRangeStart,
      terminalRangeEnd: terminalRangeEnd,
      terminalAccess: sessionStorage.getItem("terminalAccess"),

      //trolleyNo:trolleyNo,
    };
    createTerminal(terminalObj);
  };

  function createTerminal(terminalObj) {
    console.log("hello reader");
    axios({
      url: config.baseUrl + "createTerminal",
      method: "POST",
      "Content-Type": "application/json",

      data: terminalObj,
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("res+++++++", res);
          // alert(res.data.message);
          // console.log(res.data.message);
          window.location.reload();
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
  }

  //button function end

  const updateTerminal = () => {
    const initialTerminalData = getTerminals();
    //const[editableTerminalData,setEditableTerminalData] =useState("");
    console.log(initialTerminalData);
  };

  const handleDeleteTerminal = (row) => {
    axios({
      url: config.baseUrl + "deleteTerminal",
      method: "DELETE",
      "Content-Type": "application/json",
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: row,
    })
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        // console.log(err);
        // if (err.response?.data.status === 401) {
        //   alert("you are unauthorized.. please login to continue");
        //   console.log("vikas04");
        // }
      });
  };

  return (
    <div className="pt-5">
      {showAll.a && (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="parkoBlue">Current Terminals</h3>
              <Button
                className=""
                onClick={handleTerminals}
                variant="outline-primary"
              >
                <i class="fa fa-plus"></i> Add New Terminal
              </Button>
            </div>
          </div>
        </div>
      )}

      {showAll.b && (
        <div className="col-12">
          <div className="card h-auto">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3">
                    <h2 className="parkoBlue" style={{ fontSize: "21px" }}>
                      Add Terminal
                    </h2>
                    <div>
                      <Button
                        onClick={cancelButton}
                        className="me-2"
                        style={{ marginRight: "10px" }}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={handleCreateTerminal}
                        className="me-2"
                        varient="outline-primary"
                      >
                        {/* {loading ? <Spinner animation="border" size="sm" /> : '  Create Operator'} */}
                        Create Terminal
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAll.c && (
        <div className="card">
          <div className="card-header">
            <h3 className="parkoBlue">Update Terminal </h3>
            <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3 ">
              <Button
                className="me-2"
                onClick={updateTerminal}
                variant="outline-primary"
              >
                {" "}
                <i class=" me-1 fa fa-plus"></i>Update Terminal{" "}
              </Button>
              <Button
                onClick={cancelButton}
                className="me-2"
                style={{ marginRight: "10px" }}
                variant="outline-primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}

      {showAll.b && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Terminal Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={terminalName}
                      onChange={(e) => {
                        setTerminalName(e.target.value);
                      }}
                      placeholder="Terminal Name"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={terminalDescription}
                      onChange={(e) => {
                        setTerminalDescription(e.target.value);
                      }}
                      placeholder="Description"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Trolley Start Range</strong>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={terminalRangeStart}
                      onChange={(e) => {
                        setTerminalRangeStart(e.target.value);
                      }}
                      placeholder="Trolley ID start range"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Trolley End Range</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={terminalRangeEnd}
                      onChange={(e) => {
                        setTerminalRangeEnd(e.target.value);
                      }}
                      placeholder="Trolley ID end range"
                    ></input>
                  </div>
                  {/* <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Number of Trolleys</strong>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={trolleyNo}
                      onChange={(e) => {
                        setTrolleyNo(e.target.value);
                      }}
                      placeholder="Number of Trolleys"
                    ></input>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showAll.c && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Terminal Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedTerminal.terminalName || ""}
                      onChange={(e) =>
                        setEditedTerminal({
                          ...editedTerminal,
                          terminalName: e.target.value,
                        })
                      }
                      placeholder="Enter Terminal Name"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Terminal Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedTerminal.description || ""}
                      onChange={(e) =>
                        setEditedTerminal({
                          ...editedTerminal,
                          description: e.target.value,
                        })
                      }
                      placeholder="Enter Terminal Description"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Trolley Start Range</strong>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={editedTerminal.trolleysNoRange.starting || ""}
                      onChange={(e) =>
                        setEditedTerminal({
                          ...editedTerminal,
                          terminalRangeStart: e.target.value,
                        })
                      }
                      placeholder="Trolley ID start Range"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Trolley End Range</strong>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={editedTerminal.trolleysNoRange.ending || ""}
                      onChange={(e) =>
                        setEditedTerminal({
                          ...editedTerminal,
                          terminalRangeEnd: e.target.value,
                        })
                      }
                      placeholder="Trolley ID end range"
                    ></input>
                  </div>
                  {/* <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Trolley Number</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedTerminal.trolleyNo || ""}
                      onChange={(e) =>
                        setEditedTerminal({
                          ...editedTerminal,
                          trolleyNo: e.target.value,
                        })
                      }
                      placeholder="Number of trolleys"
                    ></input>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showAll.a && (
        <div className="card">
          <div className="card-body">
            <DataTable
              columns={terminalColumns}
              data={allTerminals}
              customStyles={tableCustomStyles}
              pagination
              // expandableRowsComponent={expandableWinningPriceTable}
              // expandableRows
              // fixedHeader
              // selectableRowsHighlight
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ManageTerminals;

import React from "react";
import { useState } from "react";

import { Button, Spinner, Badge } from "react-bootstrap";
import axios from "axios";
import config from "../../services/config";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import "../pages/ServiceAvailability.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./customSelect.css";
import Cookies from "js-cookie";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ServiceAvailabilityReport = () => {
  //terminal ID and Name variable
  const terminalIdName = JSON.parse(
    window.sessionStorage.getItem("terminalIdName")
  );
  console.log("terminalIdName: ", terminalIdName);

  //data table variables
  // const [zones, setZones] = useState("");
  // const [totalTrolleys, setTotalTrolleys] = useState("");
  // const [avgOccupancy, setAvgOccupancy] = useState("");
  // const [serviceAvailability, setServiceAvailability] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [error, setError] = useState("");

  const [options, setOptions] = useState(terminalIdName ?? []);
  const [selectedOption, setSelectedOption] = useState("");
  // const [data, setData] = useState([]);

  //data table
  const columns = [
    {
      name: "Zone Name",
      selector: (row) => row.zoneName,
    },
    {
      name: "Total Trolleys",
      selector: (row) => row.Availeblespace,
    },
    {
      name: "Average Availability/Month",
      selector: (row) => row.averageOfTrolleys,
    },
    {
      name: "Service Availability",
      selector: (row) => 100 - row.percentage + "%",
    },
  ];

  const [reportData, setReportData] = useState([]);
  // const data = [];
  useEffect(() => {
    // getReportData(); //***This was causing the app crash on service report page load***
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);

  function getDateString(datetime) {
    let d = datetime.getDate();
    let m = datetime.getMonth() + 1;
    return `${datetime.getFullYear()}/${m < 10 ? "0" + m : m}/${
      d < 10 ? "0" + d : d
    }`;
  }

  function getReportData() {
    if (!error) {
      let _terminalId = terminalIdName?.filter(
        (item) => item.terminalName === selectedOption
      );
      // let TerminalID = _terminalId.map((e) => {
      //   TerminalID = e._id;
      // });

      // console.log('_terminalId: ', _terminalId[0]?._id);

      const reportObj = {
        terminalId: _terminalId[0]?._id,
        // zoneId: zoneId,
        start: getDateString(startDate),
        end: getDateString(endDate),
      };
      console.log("reportObj: ", reportObj);

      try {
        axios({
          url: config.baseUrl + "getTerminalTrolleyAvailablity",
          method: "POST",
          "Content-Type": "application/json",
          data: reportObj,
        })
          .then((res) => {
            console.log("res", res);
            if (res.status === 200) {
              // alert(res.data.message)
              setReportData(res.data.terminalReports);

              // console.log(res.data.message);
              // window.location.reload()
            } else if (res.status === 201) {
              alert(res.data.message);
              console.log(res.data.message);
            } else if (res.status === 401) {
              alert("you are unauthorized.. please login to continue");
              window.location = "/page-login";
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response?.data.status === 401) {
              alert("you are unauthorized.. please login to continue");
              //   window.location = '/page-login'
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  const tableCustomStyles = {
    headCells: {
      style: {
        // fontSize: "18px",
        // fontWeight: "bold",
        // paddingLeft: '41px',
        marginRight: "-3px",
        color: "#f8857e",
        width: "180px",

        backgroundColor: "#fef3f2",
      },
    },
    cells: {
      style: {
        marginRight: "1px",
        width: "165px",
        textAlign: "center",
        // override the cell padding for data cells
      },
    },
  };

  useEffect(() => {
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);

  // function getTerminals() {
  //   axios({
  //     url: config.baseUrl + "getTerminals",
  //     method: "POST",
  //     "Content-Type": "application/json",
  //   })
  //     .then((res) => {
  //       console.log("res", res);
  //       if (res.status === 200) {
  //         // alert(res.data.message)
  //         setOptions(res.data.data);
  //         // console.log(res.data.message);
  //         // window.location.reload()
  //       } else if (res.status === 201) {
  //         alert(res.data.message);
  //         console.log(res.data.message);
  //       } else if (res.status === 401) {
  //         alert("you are unauthorized.. please login to continue");
  //         window.location = "/page-login";
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response?.data.status === 401) {
  //         alert("you are unauthorized.. please login to continue");
  //         //   window.location = '/page-login'
  //       }
  //     });
  // }

  //Drop down
  const TerminalSelector = () => {
    return (
      <div>
        <h4>Select a Terminal</h4>
        <CustomSelect
          options={options}
          onChange={(e) => setSelectedOption(e.target.value)}
          value={selectedOption}
          required
        />
      </div>
    );
  };

  const CustomSelect = ({ options, onChange, value }) => {
    return (
      <div className="custom-select">
        <select onChange={onChange} value={value}>
          <option value="">Select</option>
          {options?.map((option) => (
            <option key={option?._id} value={option?.terminalName}>
              {option.terminalName}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const [startDate, setStartDate] = useState(new Date());
  const nextDate = new Date(startDate);
  const [endDate, setEndDate] = useState(
    new Date(nextDate.setDate(nextDate.getDate() + 1))
  );
  const handleStartDateChange = (date) => {
    setStartDate(date);

    // Update endDate when startDate changes
    setEndDate();
  };

  const getNextDate = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1); // Add one day
    return nextDay;
  };

  const DateRangePicker = () => {
    return (
      <>
        <div>
          <h4>Start Date</h4>
          <DatePicker
            selected={startDate}
            value={startDate}
            onChange={(date) => handleStartDateChange(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            required
          />
        </div>
        <div>
          <h4>End Date</h4>
          <DatePicker
            selected={endDate}
            value={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate ? getNextDate(startDate) : null}
            required
          />
        </div>
      </>
    );
  };

  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const dateValidation = () => {
    if (date2 > date1) {
      setError("");
      return true;
    } else {
      return false;
    }
  };

  const getReport = () => {
    console.log(date1);
    console.log(date2);
    const isDateValid = dateValidation();
    const isTerminalValid = terminalValidation();

    if (isDateValid && isTerminalValid) {
      console.log("Success");
      setError("");
      getReportData();
    } else if (!isDateValid) {
      setError(
        "End date must be greater than Start date\nPlease recheck your dates (MM/DD/YYYY)"
      );
    }
  };

  const terminalValidation = () => {
    if (!selectedOption) {
      setError("Please select a terminal");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  let errorMessage = error;
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    const sheetData = reportData.map((row) => ({
      "Zone Name": row.zoneName,
      "Total Trolleys": row.Availeblespace,
      "Average Availability/Month": row.averageOfTrolleys,
      "Service Availability": `${100 - row.percentage}%`,
    }));

    const sheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const excelBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(
      excelBlob,
      `Service Availability Report ${formattedDate(
        startDate
      )} to ${formattedDate(endDate)}.xlsx`
    );
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of year

    return `${day}/${month}/${year}`;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="pt-5">
      {errorMessage && (
        <p style={{ color: "red", textAlign: "center", fontSize: 15 }}>
          {errorMessage}
        </p>
      )}
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="parkoBlue" style={{ fontSize: "1.25rem" }}>
              Service Availability Report
            </h3>
            {/* Drop down menu and 2 date pickers */}
            <TerminalSelector />
            <DateRangePicker />
            <Button className="" onClick={getReport} variant="outline-primary">
              <i class="fa fa-plus"></i> Get Report
            </Button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleExport}
              className="m-1"
              variant="outline-primary"
              disabled={!reportData.length > 0}
            >
              Export to Excel
            </Button>
          </div>
          <DataTable
            columns={columns}
            data={reportData}
            customStyles={tableCustomStyles}
            pagination
            // expandableRowsComponent={expandableWinningPriceTable}
            // expandableRows
            // fixedHeader
            // selectableRowsHighlight
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceAvailabilityReport;

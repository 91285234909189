import React, { Fragment, useEffect, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import { ChatBox } from "../ChatBox";
import Home from "../../components/Dashboard/Home";
import Cookies from "js-cookie";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => {
    setToggle(toggle === name ? "" : name); 
  };
  const [userRole, setUserRole] = useState(null);

    useEffect(() => {  
      const roleFromCookie = Cookies.get("Level");
      setUserRole(roleFromCookie);
    }, []);
  return (
    <Fragment>
      <NavHader />
      <ChatBox onClick={() => onClick("chatbox")} toggle={toggle}  />
      <div className={`${userRole === "5" ? "pt-5":""}`}>
      <Header
      
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      {userRole === "5" ?(
        <></>
      ):(<SideBar />)}
      </div>
      
    </Fragment>
  );
};

export default JobieNav;

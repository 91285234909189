import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import KIAL_logo from "../../../images/KIAL-logo.png";
import Cookies from "js-cookie";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);


    const [userRole, setUserRole] = useState(null);

    useEffect(() => {  
      const roleFromCookie = Cookies.get("Level");
      setUserRole(roleFromCookie);
    }, []);


  return (

    <>
    {/* Conditionally render the nav header based on userRole */}
    {userRole !== "5" && (
      <div className={userRole !== "5" ? "nav-header" : ""} style={{ backgroundColor: "#080926" }}>
        <Link to="/dashboard" className="brand-logo">
          {background.value === "dark" || navigationHader !== "color_1" ? (
            <>
              <h3 className="text-white">TMS Admin</h3>
            </>
          ) : (
            <>
              <h3 className="text-white d-none d-md-block">TMS Admin</h3>
              <div className="col-md-1 col-12 d-flex justify-content-end align-items-center d-block d-md-none">
                <div className="">
                  <img
                    src={KIAL_logo}
                    alt="Logo"
                    className="img-fluid rounded-circle mt-1"
                    width={100}
                    height={"auto"}
                  />
                </div>
              </div>
            </>
          )}
        </Link>
  
        <div
          className="nav-control"
          onClick={() => {
            setToggle(!toggle);
            openMenuToggle();
          }}
        >
          <div className={`hamburger ${toggle ? "is-active" : ""} text-warning`}>
            <span className="line text-warning"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
    )}
  </>
   
  );
};

export default NavHader;

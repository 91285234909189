import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "font-awesome/css/font-awesome.min.css";
import Logo from "../../../images/logo.jpeg";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const SideBar = () => {
  const menuItems = {
    0: [
      {
        path: "/dashboard",
        label: "Dashboard",
        icon: "flaticon-025-dashboard",
      },
      // {
      //   path: "/app-trolly",
      //   label: "Manage Trolleys",
      //   icon: "flaticon-041-graph",
      // },
      // {
      //   path: "/app-reader",
      //   label: "Manage Reader",
      //   icon: "flaticon-022-copy",
      // },
      // {
      //   path: "/app-admin",
      //   label: "Manage Admins",
      //   icon: "fa fa-user",
      // },
      // {
      //   path: "/app-terminals",
      //   label: "Manage Terminals",
      //   icon: "flaticon-041-graph",
      // },
      // { path: "/app-zone", label: "Manage Zone", icon: "flaticon-041-graph" },
      {
        path: "/app-NotificationReport",
        label: "Notification Report",
        icon: "flaticon-041-graph",
      },
      // {
      //   path: "/app-trolleyBatches",
      //   label: "Manage Batches",
      //   icon: "flaticon-087-stop",
      // },
      // {
      //   path: "/app-TrolleyMaintenance",
      //   label: "Trolley Maintenance",
      //   icon: "flaticon-041-graph",
      // },
      {
        path: "/app-sar", //serviceAvailabilityReport
        label: "Service Availability Report",
        icon: "flaticon-041-graph",
      },
    ],
    1: [
      {
        path: "/dashboard",
        label: "Dashboard",
        icon: "flaticon-025-dashboard",
      },
      {
        path: "/app-trolly",
        label: "Manage Trolley",
        icon: "flaticon-041-graph",
      },
      {
        path: "/app-reader",
        label: "Manage Reader",
        icon: "flaticon-041-graph",
      },
    ],
    2: [
      {
        path: "/dashboard",
        label: "Dashboard",
        icon: "flaticon-025-dashboard",
      },
      {
        path: "/app-reader",
        label: "Manage Reader",
        icon: "flaticon-041-graph",
      },
    ],
    3: [
      {
        path: "/dashboard",
        label: "Dashboard",
        icon: "flaticon-025-dashboard",
      },
      {
        path: "/app-reader",
        label: "Manage Reader",
        icon: "flaticon-041-graph",
      },
    ],
    4: [
      {
        path: "/dashboard",
        label: "Dashboard",
        icon: "flaticon-025-dashboard",
      },
      {
        path: "/app-trolly",
        label: "Manage Trolley",
        icon: "flaticon-041-graph",
      },
    ],
    5: [{}, {}],
  };

  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const [userRole, setUserRole] = useState(null);

  const userPermissions = menuItems[userRole] || [];

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  let path = window.location.pathname;
  // console.log('path: ', path);
  // path = path.split("/");
  // path = path[path.length - 1];

  function onLogout() {
    sessionStorage.removeItem("clientadminlogin");
    sessionStorage.removeItem("ClientName");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("ClientImgUrl");
    // dispatch(logout(props.history));
    window.location = "page-login";
  }

  return (
    <>
      {userRole === "5" ? (
        <></>
      ) : (
        <div
          className={`deznav  ${iconHover} ${
            sidebarposition.value === "fixed" &&
            sidebarLayout.value === "horizontal" &&
            headerposition.value === "static"
              ? hideOnScroll > 120
                ? "fixed"
                : ""
              : ""
          } `}
        >
          <PerfectScrollbar
            className="deznav-scroll"
            style={{ backgroundColor: "#080926" }}
          >
            {/* <Dropdown className="dropdown header-bx">
          <Dropdown.Toggle
            variant=""
            as="a"
            className="nav-link i-false c-pointer header-profile2 position-relative"
          >
            <div className="header-img position-relative z-3">
              <img className="img-fluid" src={Logo} alt="" />
              <svg
                className="header-circle"
                width="130"
                height="130"
                viewBox="0 0 130 130"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M130 65C130 100.899 100.899 130 65 130C29.1015 130 0 100.899 0 65C0 29.1015 29.1015 0 65 0C100.899 0 130 29.1015 130 65ZM4.99306 65C4.99306 98.1409 31.8591 125.007 65 125.007C98.1409 125.007 125.007 98.1409 125.007 65C125.007 31.8591 98.1409 4.99306 65 4.99306C31.8591 4.99306 4.99306 31.8591 4.99306 65Z"
                  fill="#FFD482"
                />
                <path
                  d="M65 2.49653C65 1.11774 66.1182 -0.00500592 67.496 0.0479365C76.3746 0.389105 85.0984 2.54751 93.1247 6.39966C101.902 10.6123 109.621 16.7428 115.711 24.3385C121.802 31.9341 126.108 40.8009 128.312 50.284C130.516 59.7671 130.562 69.6242 128.446 79.1274C126.33 88.6305 122.106 97.5369 116.087 105.189C110.067 112.841 102.406 119.043 93.6677 123.337C84.9299 127.631 75.3391 129.907 65.6037 129.997C56.7012 130.08 47.8858 128.333 39.7012 124.875C38.4312 124.338 37.895 122.847 38.48 121.598C39.065 120.35 40.5495 119.817 41.8213 120.35C49.3273 123.493 57.4027 125.08 65.5573 125.004C74.5449 124.921 83.399 122.819 91.4656 118.855C99.5322 114.891 106.605 109.166 112.162 102.102C117.72 95.0375 121.619 86.8153 123.572 78.0421C125.526 69.269 125.484 60.1691 123.449 51.4145C121.414 42.6598 117.438 34.4741 111.816 27.4619C106.193 20.4497 99.0674 14.7901 90.9643 10.9011C83.6123 7.3726 75.6263 5.38343 67.4958 5.04499C66.1182 4.98764 65 3.87533 65 2.49653Z"
                  fill="var(--primary)"
                />
              </svg>
              <div className="header-edit position-absolute">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    size="xl"
                    color="green"
                    icon={faGear}
                  />
                </svg>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            className="dropdown-menu dropdown-menu-end"
          >
            <LogoutPage />
          </Dropdown.Menu>
          <div className="header-content ms-2">
            <span className="font-w500">
              {sessionStorage.getItem("ClientName")}
            </span>
          </div>
        </Dropdown> */}

            <ul className="metismenu" id="menu">
              {userPermissions?.map((menuItem, index) => (
                <li
                  key={index}
                  className={`${
                    path?.includes(menuItem?.path) ? "mm-active" : ""
                  }`}
                  style={{ fontSize: "0.8125rem" }}
                >
                  <Link
                    style={{ fontSize: "0.9rem" }}
                    className={`${path === menuItem?.path ? "mm-active" : ""}`}
                    to={menuItem?.path}
                  >
                    <i className={menuItem.icon}></i>
                    <span className="menu-text">{menuItem?.label}</span>{" "}
                    {/* Assuming text is wrapped in a <span> */}
                  </Link>
                </li>
              ))}

              <li
                className="d-md-none"
                onClick={onLogout}
                style={{ cursor: "pointer", fontSize: "0.9rem" }}
              >
                <p className="text-center">
                  {" "}
                  <i
                    class="fa-solid fa-power-off fa-lg me-2 mt-5"
                    //className="img-fluid rounded-circle"
                    width={100}
                    height={"auto"}
                    style={{ cursor: "pointer" }}
                  ></i>
                  Logout
                </p>
              </li>
            </ul>

            <div className="copyright">
              <h6 className="text-white-50">
                Kempegowda International Airport TMS Dashboard{" "}
                <span className="fs-10 font-w400 text-white-50">
                  {" "}
                  © {new Date().getFullYear()} All Rights Reserved
                </span>
              </h6>
              <p className="fs-12 mb-4 text-white-50">
                Made with{" "}
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  size="xl"
                  color="#f8857e"
                  icon={faHeart}
                />{" "}
                by{" "}
                <a
                  className="text-white-50"
                  href="http://liquidlab.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Liquidlab
                </a>
              </p>
            </div>
          </PerfectScrollbar>
        </div>
      )}
    </>
  );
};

export default SideBar;

import React, { useEffect, useState } from "react";

import { Clock } from "../../components/Dashboard/components/Clock";
import { Badge, Dropdown } from "react-bootstrap";
import KIAL_logo from "../../../images/KIAL-logo.png";
import Cookies from "js-cookie";
// import { useMediaQuery } from "react-responsive";

const Header = ({ onNote }) => {
  // const [badgeNumber, setBadgeNumber] = useState(5);
  // const isMobile = useMediaQuery({ maxWidth: 600 });

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);
  const isMobileView = window.innerWidth < 700;

  const LogoutDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className="dropdown d-none d-md-block">
        <div className="">
          <i
            class="fa-solid fa-power-off fa-lg"
            //className="img-fluid rounded-circle"
            width={100}
            height={"auto"}
            onClick={toggleDropdown}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
        {isOpen && (
          <div
            className="dropdown-content"
            style={{
              position: "absolute",
              top: "100%", // Adjust the distance from the top as needed
              left: 0,
              zIndex: 1,
            }}
          >
            {/* Your dropdown content goes here */}
            <p style={{ fontSize: 15, cursor: "pointer" }} onClick={onLogout}>
              Logout
            </p>
          </div>
        )}
      </div>
    );
  };

  function onLogout() {
    sessionStorage.removeItem("clientadminlogin");
    sessionStorage.removeItem("ClientName");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("ClientImgUrl");
    // dispatch(logout(props.history));
    window.location = "page-login";
  }
  return (
    <div
      className={`header`}
      style={{ backgroundColor: "#030414", height: "8vh" }}
    >
      <nav className={`navbar navbar-expand-lg navbar-dark d-flex d-md-flex d-block flex-row-reverse row-12 `}>
        {/* <div className="col-md-1 col-2 d-flex justify-content-center align-items-center d-block d-md-none">
          <div
            onClick={() => onNote()}
            style={{
              marginTop: "20px",
              width: "3rem",
              height: "3rem",
              position: "relative",
            }}
          >
            <i class="fa-regular fa-bell" style={{ fontSize: "1.7rem" }}></i>{" "}
            <Badge
              bg="danger"
              text="white"
              className="position-absolute "
              style={{
                top: "0",
                left: "1rem",
                width: "0 .3rem",
                height: "1.2rem",
              }}
            >
              {badgeNumber}
            </Badge>
          </div>
          <span className="badge light text-white bg-primary rounded-circle"></span>
        </div> */}
        <div className="col-md-1 col-10 d-flex justify-content-center align-items-center d-block d-md-none">
          <h3 className="text-uppercase mb-0 text-white fs-14 text-center">
            Kempegowda International <br></br>Airport TMS dashboard
          </h3>
        </div>
        <div className="col-md-1 col-12 d-flex justify-content-end align-items-center d-none d-md-block">
          <div className="">
            <img
              src={KIAL_logo}
              alt="Logo"
              className="img-fluid rounded-circle mt-1"
              width={100}
              height={"auto"}
            />
          </div>
        </div>
        <div
          className="d-none d-md-block justify-content-end align-items-center"
          style={{
            marginTop: "18px",
            width: "3rem",
            height: "3rem",
            position: "relative",
            paddingLeft: "5rem",
            paddingRight: "3rem",
          }}
        >
          <LogoutDropdown />
        </div>
        <div className="col-md-1 col-12 justify-content-end d-none d-md-block">
          <Clock />
        </div>

        <div className="col-md-2 d-flex justify-content-center align-items-center d-none d-md-block">
          {/* <div
            onClick={() => onNote()}
            style={{
              marginTop: "20px",
              width: "3rem",
              height: "3rem",
              position: "relative",
            }}
          >
            <i class="fa-regular fa-bell" style={{ fontSize: "1.7rem" }}></i>{" "}
            <Badge
              bg="danger"
              text="white"
              className="position-absolute "
              style={{
                top: "0",
                left: "1rem",
                width: "0.3rem",
                height: "1.2rem",
              }}
            >
              {badgeNumber}
            </Badge>
          </div>
          <span className="badge light text-white bg-primary rounded-circle"></span> */}
        </div>

        <div className={`col-md-12 col-12   `}>
          <h3
            className={`text-uppercase mb-0 text-white text-md-left  d-none d-md-block ${
              userRole === "5" ? "text-center" : "text-end "
            }`}
          >
            {" "}
            Kempegowda International Airport TMS dashboard
          </h3>
        </div>
      </nav>
    </div>
  );
};

export default Header;

import React, { useState } from "react";
import "../Home.css";
import { Badge, Row } from "react-bootstrap";
import { ChatBox } from "../../../layouts/ChatBox";

const ZoneSection = ({ allZone, currentStatusGraph }) => {
  const sortedZones = allZone;
  const zone = currentStatusGraph;
  const [badgeNumber, setBadgeNumber] = useState(5);

  const [toggle, setToggle] = useState("");
  const notification = (name) => {
    setToggle(toggle === name ? "" : name);
  };

  return (
    <div className="h-100 pb-2">
      <ChatBox
        style={{ backgroundColor: "red" }}
        onClick={() => notification("chatbox")}
        toggle={toggle}
      />
      {/* <h5 className="text-white">Active Zones</h5> */}

      <div
        className="rounded-3 h-100  d-flex align-items-center"
        // style={{ backgroundColor: "#050e26" }}
      >
        {/* maxHeight: "69%", */}
        {sortedZones?.length > 0 ? (
          <div className="d-flex flex-wrap align-items-center justify-content-center">
          {/* <div className="d-flex"> */}
            {sortedZones?.map((p, i) => {
              let backgroundColorClass = "";
              if (p?.status === "green") {
                backgroundColorClass = "bg-green"; // Set background color class for 'hig'
              } else if (p?.status === "red") {
                backgroundColorClass = "bg-danger"; // Set background color class for 'low'
              } else if (p?.status === "orange") {
                backgroundColorClass = "bg-warning"; // Set default background color class
              }
              return (
                <Row
                  key={i}
                  className=" card mt-4 border border-danger "
                  style={{
                    background: "linear-gradient(-45deg, #0f3460, #1c1642)",
                    width: "9.7rem",
                    height: " 12rem",
                    margin:".08rem"
                  }}
                >
                  <div
                    className={`card-header d-flex p-0 pt-1 m-0 ${backgroundColorClass}`}
                  >
                    <div className="p-0 m-0 col-md-12 d-flex flex-column align-items-center justify-content-center w-100">
                      {/* <div
                        className="d-flex justify-content-end px-4"
                        style={{
                          width: "100%",
                          // height: "3rem",
                          position: "relative",
                        }}
                        // onClick={() => notification('notification')}
                        onClick={() => notification("chatbox")}
                      >
                        <i
                          className="fa-regular fa-bell"
                          style={{ fontSize: "1.2rem" }}
                        ></i>{" "}
                       <Badge
                          bg="warning"
                          text="white"
                          className="position-absolute "
                          style={{
                            top: "0",
                            left: "80%",
                            width: "0.4rem",
                            height: "1rem",
                            textAlign:"center"
                          }}
                        >
                          {badgeNumber}{" "}
                        </Badge>
                      </div> */}

                      {/* <p className="col-6 text-white w-100 text-center p-0 m-0">{`Zone ${p.zoneNo}`}</p> */}
                      <p className="col-6 text-white w-100 text-center p-0 mb-2">
                        {p?.zoneName}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 d-flex justify-content-center align-items-center pt-3  p-0 m-0">
                    <div className="col-6 ">
                      <p className="d-flex align-items-center justify-content-center p-0 mt-2 mb-0 smaller-text">
                        Total
                      </p>
                      <h3 className="text-white d-flex align-items-center justify-content-center p-0 mb-0">
                        {p?.Availeblespace}
                      </h3>
                    </div>
                    <div className="col-6 p-0 m-0">
                      <p className="d-flex align-items-center justify-content-center p-0 mt-2 mb-0 smaller-text">
                        Avl
                      </p>
                      <h3 className="text-white d-flex align-items-center justify-content-center p-0 mb-0">
                        {p?.trolleyCount}
                      </h3>
                    </div>
                  </div>

                  
                  <div className="col-md-12 d-flex justify-content-center align-items-center pt-0  p-0 m-0">
                    <div className="col-6 ">
                      <p style={{fontSize:"9px"}} className="d-flex align-items-center justify-content-center p-0 mt-2 mb-0 smaller-text">
                      LowThreshold
                      </p>
                      <h3 className="text-white d-flex align-items-center justify-content-center p-0 mb-0">
                        {p?.LowThreshold}
                      </h3>
                    </div>
                    <div className="col-6 p-0 m-0">
                      <p style={{fontSize:"9px"}} className="d-flex align-items-center justify-content-center p-0 mt-2 mb-0 smaller-text">
                      HighThreshold
                      </p>
                      <h3 className="text-white d-flex align-items-center justify-content-center p-0 mb-0">
                        {p?.HighThreshold}
                      </h3>
                    </div>
                  </div>

                  {/* <p className="d-flex align-items-center justify-content-center p-0 m-0">
                    {`${p.AvailibleTrolly} trollyes is filld`}
                  </p> */}
                </Row>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default ZoneSection;

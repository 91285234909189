import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
// import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import axios from "axios";
import config from "../../services/config";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./manageZone.css";
import Cookies from "js-cookie";
import Select from "react-select";

export const TrolleyBatches = () => {
  const [data, setData] = useState({ a: true, b: false, c: false });
  const [BatchNo, setBatchNo] = useState("");
  const [AllBatches, setAllBatches] = useState([]);
  const [Description, setDescription] = useState("");
  const [Trolleys, setTrolleys] = useState([]);
  console.log("Trolleys: ", Trolleys);
  const [selectedTrolley, setSelectedTrolley] = useState([]);

  let errorsObj = { username: "", password: "", level: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editedUser, setEditedUser] = useState({});
  const [userRole, setUserRole] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = Trolleys.map((trolley) => ({
    value: trolley._id,
    label: trolley.trolleyNo,
  }));
 

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      p: "2",
    }),
  };
  useEffect(() => {
    getAllBatches()
    getAllTrolleys();
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);
  const columns = [
    {
      name: "Sl no",
      selector: (row, index) => index + 1,
      width: "80px",
      headerStyle: (selector, id) => {
        return { textAlign: "center" };
      },
    },
    {
      name: "BatchNo",
      selector: (row) => row.BatchNo,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
    },
     
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          className="edit-btn"
          onClick={() => {
            showFroms();
            handleEdit(row);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button className="delete-btn" onClick={() => deleteAdmin(row)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      ),
    },
  ];

  const tableCustomStyles = {
    headCells: {
      style: {
        marginRight: "-3px",
        color: "#f8857e",
        width: "180px",

        backgroundColor: "#fef3f2",
      },
    },
    cells: {
      style: {
        marginRight: "1px",
        width: "165px",
        textAlign: "center",
      },
    },
  };

  function createAdmin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = {};

    if (!BatchNo) {
      errorObj.username = "BatchNo is Required";
      error = true;
    }
    if (!Description) {
      errorObj.level = "Description is Required";
      error = true;
    }

    if (error) {
      setErrors(errorObj);
      return;
    }
    setErrors({});
    handleCreateBatch();
  }
  const deleteAdmin = () => {};

  const handleEdit = (row) => {
    // console.log("Editing row:", row);
    setSelectedUser(row);
    setEditedUser({ ...row });
  };

  const showFroms = () => {
    setData({ c: true });
  };
  const handleBatch = () => {
    setData({ a: false, b: true });
  };

  const cancelButton = () => {
    setData({ a: true, b: false });
  };

  const handleCreateBatch = () => {
    const BatchObj = {
      BatchNo: BatchNo,
      Description: Description,
      Trolleys: selectedOptions,
    };
    console.log("BatchObj: ", BatchObj);
    CreateBatches(BatchObj);
  };

  const getAllTrolleys = () => {
    const terminalAccessObj={
      terminalAccess:JSON.parse(sessionStorage.getItem("terminalAccess"))
    }
    axios({
      url: config.baseUrl + "getAllTrolley",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: terminalAccessObj,
    })
      .then((res) => {
        if (res.status === 200) {
          setTrolleys(res?.data?.data);
        } else if (res.status === 201) {
        } else if (res.status === 401) {
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          alert("Unauthorized. Please login to continue");
        }
      });
  };
  


  const getAllBatches = () => {
    axios({
      url: config.baseUrl + "getAllBatches",
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.status === 200) {
          setAllBatches(res.data.allBatches);
        } else if (res.status === 201) {
        } else if (res.status === 401) {
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          alert("Unauthorized. Please login to continue");
        }
      });
  };


  function CreateBatches(BatchObj) {
    axios({
      url: config.baseUrl + "TrolleysBatchValidation",
      method: "POST",
      "Content-Type": "application/json",
      data:BatchObj
    })
      .then((res) => {
        if (res.status === 200) {
        } else if (res.status === 201) {
        } else if (res.status === 401) {
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
        }
      });
  }

  const UpdateAdmin = () => {};
  return (
    <div className="py-5">
      {data.a && (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="parkoBlue">Manage Trolley Batches</h3>
              <Button
                className=""
                onClick={handleBatch}
                variant="outline-primary"
              >
                <i class="fa fa-plus"></i> Add New Batch
              </Button>
            </div>
          </div>
        </div>
      )}

      {data.b && (
        <div className="col-12">
          <div className="card h-auto">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3">
                    <h2 className="parkoBlue" style={{ fontSize: "21px" }}>
                      Add a New Batch
                    </h2>
                    <div>
                      <Button
                        onClick={cancelButton}
                        className="me-2"
                        style={{ marginRight: "10px" }}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={handleCreateBatch}
                        className="me-2"
                        varient="outline-primary"
                      >
                        Create Batches
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.b && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>Batch No</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={BatchNo}
                      onChange={(e) => {
                        setBatchNo(e.target.value);
                      }}
                      placeholder="Batch No"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={Description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      placeholder="Description"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-6">
                    <label className="mb-1 text-black" htmlFor="form3Example3">
                      <strong>Trolleys</strong>
                    </label>
                    <Select
                      id="form3Example3"
                      options={options}
                      value={selectedOptions}
                      onChange={(selected) => setSelectedOptions(selected)}
                      placeholder="Search Trolleys..."
                      isMulti
                      styles={customStyles}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* 
      {data.c && (
        <div className="card">
          <div className="card-header">
            <h3 className="parkoBlue">Update Admins </h3>
            <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3 ">
              <Button
                className="me-2"
                onClick={UpdateAdmin}
                variant="outline-primary"
              >
                {" "}
                <i class=" me-1 fa fa-plus"></i>Update Admins{" "}
              </Button>
              <Button
                onClick={cancelButton}
                className="me-2"
                style={{ marginRight: "10px" }}
                variant="outline-primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}

      {data.c && (
        <div>
          <div className="card">
            <div className="card-body">
              <div className="search-results">
                <form>
                  <div className="row">
                    <div className="form-outline mb-4">
                      {errors.username && (
                        <div className="text-danger fs-12">
                          {errors.username}
                        </div>
                      )}
                      <input
                        type="text"
                        id="form3Example2"
                        className="form-control"
                        value={editedUser.username || ""}
                        onChange={(e) =>
                          setEditedUser({
                            ...editedUser,
                            username: e.target.value,
                          })
                        }
                      />
                      <label className="form-label" htmlFor="form3Example2">
                        Batch No
                      </label>
                    </div>
                  </div>

                  <div className="form-outline mb-4">
                    {errors.password && (
                      <div className="text-danger fs-12">{errors.password}</div>
                    )}
                    <input
                      type="password"
                      id="form3Example4"
                      className="form-control"
                      value={editedUser.password || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          password: e.target.value,
                        })
                      }
                    />
                    <label className="form-label" htmlFor="form3Example4">
                      Description
                    </label>

                    <div className="form-outline mb-4">
                      {errors.level && (
                        <div className="text-danger fs-12">{errors.level}</div>
                      )}

                      <div className="position-relative">
                        <select
                          id="form3Example3"
                          className="form-control"
                          value={editedUser.level || "0"}
                          onChange={(e) =>
                            setEditedUser({
                              ...editedUser,
                              level: e.target.value,
                            })
                          }
                        >
                          <option value="Select One Option">
                            Select One Option
                          </option>
                          <option value="0">0</option>
                        </select>
                        <i className="fas fa-caret-down position-absolute top-50 end-0 translate-middle-y"></i>
                        <label className="form-label" htmlFor="form3Example3">
                          Troleys
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {data.a && (
        <div className="card">
          <div className="card-body">
            <DataTable
              columns={columns}
              data={AllBatches}
              customStyles={tableCustomStyles}
              pagination
              // expandableRowsComponent={expandableWinningPriceTable}
              // expandableRows
              // fixedHeader
              // selectableRowsHighlight
            />
          </div>
        </div>
      )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import axios from "axios";
import config from "../../services/config";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

const ManageReader = () => {
  const handleReader = () => {
    // setShowReader(false);
    // setShowReaderForm(true);
    setData({ a: false, b: true });
  };
  const cancelButton = () => {
    // setShowReader(true);
    // setShowReaderForm(false);
    setData({ a: true, b: false });
  };
  const handleCreateReader = () => {
    const readerObj = {
      // zoneID: zoneId,
      // zoneID: zoneId,
      zoneName: zoneName,
      readerId: readerId,
      description: readerDescription,
      ipAddress: IpAddress,
      terminalAccess: sessionStorage.getItem("terminalAccess"),

    };

    createReader(readerObj);
  };
  const [showReader, setShowReader] = useState(true);
  const [showReaderForm, setShowReaderForm] = useState(false);
  const [IpAddress, setIpAddress] = useState("");
  console.log("IpAddress: ", IpAddress);
  const [allZone, setAllZone] = useState([]);
  const [allReader, setAllReader] = useState([]);
  console.log("allReader: ", allReader);
  // const [zoneId, setZoneId] = useState("");
  const [zoneName, setZoneName] = useState("");

  const [readerId, setReaderId] = useState("");
  const [readerDescription, setReaderDescription] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [editReader, setEditReader] = useState({});
  const [data, setData] = useState({ a: true, b: false, c: false });

  const columns = [
    {
      name: "Sl no",
      selector: (row, index) => index + 1,
      width: "80px", // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" }; // removed partial line here
      },
    },
    {
      name: "Reader No",
      selector: (row) => row.readerId,
    },
    // {
    //   name: "Ip Address",
    //   selector: (row) => row.IpAddress,
    // },
    {
      name: "Descriptions",
      selector: (row) => row.description,
    },
    {
      name: "Zone Name",
      selector: (row) => row.zoneName,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => {
        if (userRole === "0" || "1" || "4") {
          return (
            <button
              className="edit-btn"
              onClick={() => {
                showFroms();
                handleEdit(row);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          );
        }
        return null;
      },
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => {
        if (userRole === "0") {
          return (
            <button className="delete-btn" onClick={() => handleDelete(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          );
        }
        return null;
      },
    },
  ];
  const tableCustomStyles = {
    headCells: {
      style: {
        marginRight: "-3px",
        color: "#f8857e",
        width: "180px",

        backgroundColor: "#fef3f2",
      },
    },
    cells: {
      style: {
        marginRight: "1px",
        width: "165px",
        textAlign: "center",
      },
    },
  };
  const handleEdit = (row) => {
    // console.log("Editing row:", row);
    // setSelectedUser(row);
    setEditReader({ ...row });
  };
  const showFroms = () => {
    setData({ c: true });
  };
  const handleDelete = () => {};
  const UpdateReader = () => {

    const newReaerData = editReader;

    axios({
      url: config.baseUrl + "updateReader",
      method: "post",
      "Content-Type": "application/json",
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: newReaerData,
    })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res, ":res____________aaaaaaa---------");
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          console.log("vikas04");
        }
      });
  };

  useEffect(() => {
    getAllZone();
    getAllReader();
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);

  function getAllZone() {
    const terminalAccessObj = {
      terminalAccess: JSON.parse(sessionStorage.getItem("terminalAccess")),
    };
    axios({
      url: config.baseUrl + "getAllZone",
      method: "POST",
      "Content-Type": "application/json",
      data: terminalAccessObj,
    })
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          // alert(res.data.message)
          setAllZone(res.data.data);
          // console.log(res.data.message);
          // window.location.reload()
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
  }

  function getAllReader() {
    const terminalAccessObj = {
      terminalAccess: JSON.parse(sessionStorage.getItem("terminalAccess")),
    };
    axios({
      url: config.baseUrl + "getAllReader",
      method: "POST",
      "Content-Type": "application/json",
      data: terminalAccessObj,
    })
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          // alert(res.data.message)
          setAllReader(res.data.data);
          // console.log(res.data.message);
          // window.location.reload()
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
  }
  function createReader(readerObj) {
    console.log("hello reader");
    axios({
      url: config.baseUrl + "createReader",
      method: "POST",
      "Content-Type": "application/json",

      data: readerObj,
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("res+++++++", res);
          // alert(res.data.message);
          // console.log(res.data.message);
          window.location.reload();
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
          //   window.location = '/page-login'
        }
      });
  }

  return (
    <div className="pt-5">
      {data.a && (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="parkoBlue">Current Readers</h3>
              {userRole === "0" && (
                <Button
                  className=""
                  onClick={handleReader}
                  variant="outline-primary"
                >
                  <i className="fa fa-plus"></i> Add New Reader
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      {data.b && (
        <>
          <div className="col-12">
            <div className="card h-auto">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3">
                      <h2 className="parkoBlue" style={{ fontSize: "21px" }}>
                        Add Reader
                      </h2>
                      <div>
                        <Button
                          onClick={cancelButton}
                          className="me-2"
                          style={{ marginRight: "10px" }}
                          variant="outline-primary"
                        >
                          Cancel
                        </Button>

                        <Button
                          onClick={handleCreateReader}
                          className="me-2"
                          varient="outline-primary"
                        >
                          {/* {loading ? <Spinner animation="border" size="sm" /> : '  Create Operator'} */}
                          Create Reader
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {data.c && (
        <>
          <div className="col-12">
            <div className="card h-auto">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3">
                      <h2 className="parkoBlue" style={{ fontSize: "21px" }}>
                        Edit Reader
                      </h2>
                      <div>
                        <Button
                          onClick={cancelButton}
                          className="me-2"
                          style={{ marginRight: "10px" }}
                          variant="outline-primary"
                        >
                          Cancel
                        </Button>

                        <Button
                          onClick={UpdateReader}
                          className="me-2"
                          varient="outline-primary"
                        >
                          {/* {loading ? <Spinner animation="border" size="sm" /> : '  Create Operator'} */}
                          Update Reader
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {data.c && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Reader No</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      // value={readerId}
                      // onChange={(e) => {
                      //   setReaderId(e.target.value)
                      // }}

                      value={editReader.readerId || ""}
                      onChange={(e) =>
                        setEditReader({
                          ...editReader,
                          readerId: e.target.value,
                        })
                      }
                      placeholder="Enter Reader No"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>IP Address</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editReader.ipAddress || ""}
                      onChange={(e) =>
                        setEditReader({
                          ...editReader,
                          IpAddress: e.target.value,
                        })
                      }
                      placeholder="Enter Ip Address"
                    ></input>
                  </div>
                  {/* <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>zoneID</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={zoneId}
                      onChange={(e) => {
                        setIpAddress(e.target.value);
                      }}
                      placeholder="Enter Zone Id"
                    ></input>
                  </div> */}

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Select Zone</strong>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={editReader.zoneName || ""}
                      onChange={(e) =>
                        setEditReader({
                          ...editReader,
                          zoneName: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Zone</option>
                      {allZone !== undefined
                        ? allZone.map((zone, index) => (
                            <option key={index} value={zone.zoneName}>
                              {zone.zoneName}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editReader.description || ""}
                      onChange={(e) =>
                        setEditReader({
                          ...editReader,
                          readerDescription: e.target.value,
                        })
                      }
                      placeholder="Type Description Here"
                    ></input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {data.b && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Reader No</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={readerId}
                      onChange={(e) => {
                        setReaderId(e.target.value);
                      }}
                      placeholder="Enter Reader No"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>IP Address</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={IpAddress}
                      onChange={(e) => {
                        setIpAddress(e.target.value);
                      }}
                      placeholder="Enter Ip Address"
                    ></input>
                  </div>
                  {/* <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>zoneID</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={zoneId}
                      onChange={(e) => {
                        setIpAddress(e.target.value);
                      }}
                      placeholder="Enter Zone Id"
                    ></input>
                  </div> */}

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Select Zone</strong>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={zoneName}
                      onChange={(e) => {
                        setZoneName(e.target.value); // Update zoneName state on change
                      }}
                    >
                      <option value="">Select Zone</option>
                      {allZone !== undefined
                        ? allZone.map((zone, index) => (
                            <option key={index} value={zone.zoneName}>
                              {zone.zoneName}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1">
                      <strong>Description</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={readerDescription}
                      onChange={(e) => {
                        setReaderDescription(e.target.value);
                      }}
                      placeholder="Type Description Here"
                    ></input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {data.a && (
        <div className="card">
          <div className="card-body">
            <DataTable
              columns={columns}
              data={allReader}
              customStyles={tableCustomStyles}
              pagination
              // expandableRowsComponent={expandableWinningPriceTable}
              // expandableRows
              // fixedHeader
              selectableRowsHighlight
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageReader;

import React, { useEffect, useState } from "react";

export const Alerts = ({ terminals, terminlaId }) => {
  const [terminalData, setTerminalData] = useState([]);
  const [type, setType] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (terminals != null && terminals.length > 0) {
      const selectedTerminal = terminals.find(
        (terminal) => terminal._id === terminlaId
      );
      if (selectedTerminal) {
        setTerminalData(selectedTerminal.notifications || []);
      } else {
        setTerminalData([]);
      }
    } else {
      setTerminalData([]);
    }
  }, [terminals, terminlaId]);

  // useEffect(() => {
  //   let newType = "";
  //   for (const message of terminalData) {
  //     if (message.type === 1) {
  //       newType = 'Trolley mismatch';
  //       break;
  //     } else if (message.type === 2 && message.isDanger === true) {
  //       newType = "Trolley count remained below the higher threshold for 20 minutes";
  //       break;
  //     } else if (message.type === 2) {
  //       newType = "Trolley count dropped below the lower threshold";
  //       break;
  //     } else if (message.type === 3) {
  //       newType = "Trolley count returned to normal levels";
  //       break;
  //     }
  //   }
  //   setType(newType);
  // }, [terminalData]);

  useEffect(() => {
    console.log("terminalData111aaa: ", terminalData);
    const messages = terminalData.map((message) => {
      let newType = "";
      if (message.type === 1) {
        newType = "Trolley mismatch";
      }
      if (message.isDanger === true && newType === "") {
        newType =
          "Trolley count remained below the higher threshold for 20 minutes";
      }
      if (message.type === 2 && newType === "") {
        newType = "Trolley count dropped below the lower threshold";
      }
      if (message.type === 3 && newType === "") {
        newType = "Trolley count returned to normal levels";
      }
      return {
        type: newType,
        zoneName: message.zoneName,
        totalTrolleys: message.totalTrolleys,
      };
    });

    console.log("messages: ", messages);
    setMessages(messages);
  }, [terminalData]);

  return (
    <div id="Notes">
      <div className="container">
        {messages.map((message, index) => (
          <div className="row justify-content-center px-2 py-1" key={index}>
            <div className="col-md-12">
              <h4>{` ${message.zoneName}`}</h4>
              <h4>{`totalTrolleys: ${message.totalTrolleys}`}</h4>
              <p
                style={{
                  color:
                    message.type === "Trolley count returned to normal levels"
                      ? "green"
                      : message.type ===
                        "Trolley count remained below the higher threshold for 20 minutes"
                      ? "red"
                      : message.type ===
                        "Trolley count dropped below the lower threshold"
                      ? "orange"
                      : message.type === "Trolley mismatch"
                      ? "blue"
                      : "",
                }}
              >{` ${message.type}`}</p>
              <hr style={{ color: "black" }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// export const serverIP = "localhost";
// export const serverIP = '192.168.1.14'//praveen
export const serverIP = "tms.liquidlab.in"; // server
// export const serverIP = 'tms.parko.in' // server
// export const serverIP = 'handheld.liquidlab.in'
// export const serverIP = 'client.intecoerp.com'
// export const serverIP='erp.linnk.com'
// export const serverIP='192.168.1.7'
// export const serverIP='13.234.48.160'
// export const serverIP='valet.parko.in'

import config from "../../services/config";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

export const SubAdmin = () => {
  const [data, setData] = useState({ a: true, b: false, c: false });
  const [username, setUsername] = useState("");
  const [level, setLevel] = useState();
  const [password, setPassword] = useState("");
  const [AllAdmin, setAllAdmin] = useState([]);
  console.log("AllAdmin: ", AllAdmin);
  let errorsObj = { username: "", password: "", level: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editedUser, setEditedUser] = useState({});

  useEffect(() => {
    getAllAdmin();
  }, []);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    { value: "0" },
    { value: "1" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    { value: "5" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };
  const handleChange = (selectedOption) => {
    setLevel(selectedOption);
  };
  const columns = [
    {
      name: "Sl no",
      selector: (row, index) => index + 1,
      width: "80px",
      headerStyle: (selector, id) => {
        return { textAlign: "center" };
      },
    },
    {
      name: "Admin Usere Name",
      selector: (row) => row.username,
    },
    {
      name: "Level",
      selector: (row) => row.level,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          className="edit-btn"
          onClick={() => {
            showFroms();
            handleEdit(row);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button className="delete-btn" onClick={() => deleteAdmin(row)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      ),
    },
  ];

  const tableCustomStyles = {
    headCells: {
      style: {
        marginRight: "-3px",
        color: "#f8857e",
        width: "180px",

        backgroundColor: "#fef3f2",
      },
    },
    cells: {
      style: {
        marginRight: "1px",
        width: "165px",
        textAlign: "center",
      },
    },
  };
  const handleEdit = (row) => {
    // console.log("Editing row:", row);
    setSelectedUser(row);
    setEditedUser({ ...row });
  };

  const showFroms = () => {
    setData({ c: true });
  };
  const handleAdmin = () => {
    setData({ a: false, b: true });
  };

  const UpdateAdmin = () => {
    const editedData = editedUser;
    axios({
      url: config.baseUrl + "UpdateAdmin",
      method: "put",
      "Content-Type": "application/json",
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: editedData,
    })
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          setData({ a: true, c: false });
          getAllAdmin()
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
        }
      });
  };

  const cancelButton = () => {
    setData({ a: true, b: false });
  };

  const handleCreateAdmin = () => {
    const adminObj = {
      username: username,
      level: parseInt(level),
      password: password,
    };
    AddAdmin(adminObj);
  };

  function createAdmin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = {};

    if (!username) {
      errorObj.username = "User Name is Required";
      error = true;
    }
    if (!level) {
      errorObj.level = "Level is Required";
      error = true;
    }
    if (!password) {
      errorObj.password = "Password is Required";
      error = true;
    }

    if (error) {
      setErrors(errorObj);
      return;
    }
    setErrors({});
    handleCreateAdmin();
  }

  function AddAdmin(adminObj) {
    axios({
      url: config.baseUrl + "adminSignUp",
      method: "POST",
      "Content-Type": "application/json",
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: adminObj,
    })
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          setData({ a: true, b: false });
          getAllAdmin();

          window.sessionStorage.setItem("clientadminlogin", true);
          window.sessionStorage.setItem("token", res.data.data.token);
          window.sessionStorage.setItem("id", res.data.data.id);
        } else if (res.status === 201) {
          alert(res.data.message);
        } else if (res.status === 202) {
          alert(res.data.message);
          console.log("res");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
        }
      });
  }

  function getAllAdmin() {
    axios({
      url: config.baseUrl + "getAllAdmin",
      method: "POST",
      "Content-Type": "application/json",
    })
      .then((res) => {
        if (res.status === 200) {
          setAllAdmin(res.data.allAdmin);
        } else if (res.status === 201) {
          alert(res.data.message);
          console.log(res.data.message);
        } else if (res.status === 401) {
          alert("you are unauthorized.. please login to continue");
          window.location = "/page-login";
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
        }
      });
  }

  const deleteAdmin = (row) => {
    axios({
      url: config.baseUrl + "deleteAdmin",
      method: "delete",
      "Content-Type": "application/json",
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: row,
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.message);
          alert(res.data.message);
          getAllAdmin();
        }
      })
      .catch((err) => {
        // console.log(err);
        // if (err.response?.data.status === 401) {
        //   alert("you are unauthorized.. please login to continue");
        //   console.log("vikas04");
        // }
      });
  };

  return (
    <div className="pt-5">
      {data.a && (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="parkoBlue">Manage Admins</h3>
              <Button
                className=""
                onClick={handleAdmin}
                variant="outline-primary"
              >
                <i class="fa fa-plus"></i> Add New Admin
              </Button>
            </div>
          </div>
        </div>
      )}

      {data.b && (
        <div className="col-12">
          <div className="card h-auto">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3">
                    <h2 className="parkoBlue" style={{ fontSize: "21px" }}>
                      Add a New Admin
                    </h2>
                    <div>
                      <Button
                        onClick={cancelButton}
                        className="me-2"
                        style={{ marginRight: "10px" }}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={handleCreateAdmin}
                        className="me-2"
                        varient="outline-primary"
                      >
                        Create Admin
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.b && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form onSubmit={createAdmin}>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-4">
                    {errors.username && (
                      <div className="text-danger fs-12">{errors.username}</div>
                    )}
                    <label className="form-label" htmlFor="form3Example2">
                      User Name
                    </label>
                    <input
                      type="text"
                      id="form3Example2"
                      className="form-control"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="User Name"
                    />
                  </div>

                  <div className="mb-3 mt-2 col-md-4">
                    {errors.level && (
                      <div className="text-danger fs-12">{errors.level}</div>
                    )}
                    <label className="form-label mb-1" htmlFor="form3Example3">
                      Level
                    </label>
                    <select
                      id="form3Example3"
                      className="form-control"
                      value={level}
                      onChange={(e) => setLevel(e.target.value)}
                    >
                      <option value="">Select One Option</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>

                  <div className="mb-3 mt-2 col-md-4">
                    {errors.password && (
                      <div className="text-danger fs-12">{errors.password}</div>
                    )}
                    <label className="form-label" htmlFor="form3Example4">
                      Password
                    </label>
                    <input
                      type="password"
                      id="form3Example4"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {data.c && (
        <div className="card">
          <div className="card-header">
            <h3 className="parkoBlue">Update Admins </h3>
            <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3 ">
              <Button
                className="me-2"
                onClick={UpdateAdmin}
                variant="outline-primary"
              >
                {" "}
                <i class=" me-1 fa fa-plus"></i>Update Admins{" "}
              </Button>
              <Button
                onClick={cancelButton}
                className="me-2"
                style={{ marginRight: "10px" }}
                variant="outline-primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}

      {data.c && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="col-md-4 mb-4">
                    {errors.username && (
                      <div className="text-danger fs-12">{errors.username}</div>
                    )}
                    <label className="form-label" htmlFor="form3Example2">
                      User Name
                    </label>
                    <input
                      type="text"
                      id="form3Example2"
                      className="form-control"
                      value={editedUser.username || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          username: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-md-4 mb-4">
                    {errors.level && (
                      <div className="text-danger fs-12">{errors.level}</div>
                    )}
                    <label className="form-label" htmlFor="form3Example3">
                      Level
                    </label>
                    <div className="position-relative">
                      <select
                        id="form3Example3"
                        className="form-control"
                        value={editedUser.level || "0"}
                        onChange={(e) =>
                          setEditedUser({
                            ...editedUser,
                            level: e.target.value,
                          })
                        }
                      >
                        <option value="">Select One Option</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                      <i className="fas fa-caret-down position-absolute top-50 end-0 translate-middle-y"></i>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    {errors.password && (
                      <div className="text-danger fs-12">{errors.password}</div>
                    )}
                    <label className="form-label" htmlFor="form3Example4">
                      Password
                    </label>
                    <input
                      type="password"
                      id="form3Example4"
                      className="form-control"
                      value={editedUser.password || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {data.a && (
        <div className="card">
          <div className="card-body">
            <DataTable
              columns={columns}
              data={AllAdmin}
              customStyles={tableCustomStyles}
              pagination
              // expandableRowsComponent={expandableWinningPriceTable}
              // expandableRows
              // fixedHeader
              // selectableRowsHighlight
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubAdmin;

import { Button } from "react-bootstrap";
import axios from "axios";
import config from "../../services/config";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./manageZone.css";
import Cookies from "js-cookie";
import Select from "react-select";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./datepicker.css"; // Import the styles

export const TrolleyMaintenance = () => {
  const [data, setData] = useState({ a: true, b: false, c: false });
  const [InDate, setInDate] = useState(new Date());
  const [inMessage, setinMessage] = useState("");
  const [typeOfMaintence, settypeOfMaintence] = useState("");
  const [outDate, setOutDate] = useState("");
  const [outMessage, setoutMessage] = useState("");
  const [Batches, setAllBatches] = useState([]);
  const [AllMaintenceDatas, setAllMaintenceDatas] = useState([]);

  //   const [selectedTrolley, setSelectedTrolley] = useState([]);

  let errorsObj = { username: "", password: "", level: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editedUser, setEditedUser] = useState({});
  const [userRole, setUserRole] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = Batches?.map((batch) => ({
    value: batch._id,
    label: batch.BatchNo,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };
  useEffect(() => {
    getAllMaintenancBatches()
    getAllBatches();
    const roleFromCookie = Cookies.get("Level");
    setUserRole(roleFromCookie);
  }, []);
  const columns = [
    {
      name: "Sl no",
      selector: (row, index) => index + 1,
      width: "80px",
      headerStyle: (selector, id) => {
        return { textAlign: "center" };
      },
    },
    {
      name: "Batch",
      selector: (row) => row.Batches,
    },
    {
      name: "In Date",
      selector: (row) => row.InDate,
    },
    {
      name: "in Message",
      selector: (row) => row.inMessage,
    },
    {
      name: "typeOfMaintence",
      selector: (row) => row.typeOfMaintence,
    },
    {
      name: "outDate",
      selector: (row) => row.outDate,
    },
    {
      name: "outMessage",
      selector: (row) => row.outMessage,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          className="edit-btn"
          onClick={() => {
            showFroms();
            handleEdit(row);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button className="delete-btn" onClick={() => deleteAdmin(row)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      ),
    },
  ];

  const tableCustomStyles = {
    headCells: {
      style: {
        marginRight: "-3px",
        color: "#f8857e",
        width: "180px",

        backgroundColor: "#fef3f2",
      },
    },
    cells: {
      style: {
        marginRight: "1px",
        width: "165px",
        textAlign: "center",
      },
    },
  };

  function createAdmin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = {};

    if (!InDate) {
      errorObj.InDate = "InDate is Required";
      error = true;
    }
    if (!inMessage) {
      errorObj.inMessage = "inMessage is Required";
      error = true;
    }
    if (!outDate) {
      errorObj.outDate = "outDate is Required";
      error = true;
    }
    if (!outMessage) {
      errorObj.outMessage = "outMessage is Required";
      error = true;
    }
    if (error) {
      setErrors(errorObj);
      return;
    }
    setErrors({});
    handleCreateBatch();
  }
  const deleteAdmin = () => {};

  const handleEdit = (row) => {
    console.log("Editing row:", row);
    setSelectedUser(row);
    setEditedUser({ ...row });
  };

  const showFroms = () => {
    setData({ c: true });
  };
  const handleBatch = () => {
    setData({ a: false, b: true });
  };

  const cancelButton = () => {
    setData({ a: true, b: false });
  };

  const handleCreateBatch = () => {
    const MaintenanceObj = {
      inDate: Math.floor(new Date().getTime() / 1000),
      inMessage: inMessage,
      batches: options,
      date: InDate,
      maintenanceType: typeOfMaintence,
    };
    console.log("BatchObj: ", MaintenanceObj);
    CreateBatches(MaintenanceObj);
  };

  const getAllBatches = () => {
    axios({
      url: config.baseUrl + "getAllBatches",
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.status === 200) {
          setAllBatches(res.data.allBatches);
        } else if (res.status === 201) {
        } else if (res.status === 401) {
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          alert("Unauthorized. Please login to continue");
        }
      });
  };


  const getAllMaintenancBatches = () => {
    axios({
      url: config.baseUrl + "getAllMaintenancBatches",
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        console.log('res:++++++++++++++++++++ ', res);
        if (res.status === 200) {
          setAllMaintenceDatas(res.data.data);
        } else if (res.status === 201) {
        } else if (res.status === 401) {
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          alert("Unauthorized. Please login to continue");
        }
      });
  };

  function CreateBatches(BatchObj) {
    axios({
      // url: config.baseUrl + "trolleyMaintenanceOut",
      url: config.baseUrl + "Trollemaintaice",
      method: "POST",
      "Content-Type": "application/json",
      data: BatchObj,
    })
      .then((res) => {
        if (res.status === 200) {
        } else if (res.status === 201) {
        } else if (res.status === 401) {
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.status === 401) {
          alert("you are unauthorized.. please login to continue");
        }
      });
  }

  const UpdateAdmin = () => {};

  return (
    <div className="py-5">
      {data.a && (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="parkoBlue">Manage Trolley Maintenance</h3>
              <Button
                className=""
                onClick={handleBatch}
                variant="outline-primary"
              >
                <i class="fa fa-plus"></i> Add New Trolley Maintenance
              </Button>
            </div>
          </div>
        </div>
      )}

      {data.b && (
        <div className="col-12">
          <div className="card h-auto">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3">
                    <h2 className="parkoBlue" style={{ fontSize: "21px" }}>
                      Add a New Trolley Maintenance
                    </h2>
                    <div>
                      <Button
                        onClick={cancelButton}
                        className="me-2"
                        style={{ marginRight: "10px" }}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={handleCreateBatch}
                        className="me-2"
                        varient="outline-primary"
                      >
                        Create Trolley Maintenance
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.b && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black" htmlFor="form3Example3">
                      <strong>Batch</strong>
                    </label>
                    <Select
                      id="form3Example3"
                      options={options}
                      value={selectedOptions}
                      onChange={(selected) => setSelectedOptions(selected)}
                      placeholder="Search Trolleys..."
                      isMulti
                      styles={customStyles}
                    />
                  </div>

                  <div className="mb-3 mt-2 col-md-6">
                    <label className="mb-1 text-black">
                      <strong>In Date</strong>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={InDate}
                      onChange={(e) => {
                        setInDate(e.target.value);
                      }}
                      placeholder="In Date"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>In Message</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={inMessage}
                      onChange={(e) => {
                        setinMessage(e.target.value);
                      }}
                      placeholder="In Message"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>Type Of Maintence</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={typeOfMaintence}
                      onChange={(e) => {
                        settypeOfMaintence(e.target.value);
                      }}
                      placeholder="Type Of Maintence"
                    ></input>
                  </div>

                  {/* <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>Out Date</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={outDate}
                      onChange={(e) => {
                        setOutDate(e.target.value);
                      }}
                      placeholder="Out Date"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>Out Message</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={outMessage}
                      onChange={(e) => {
                        setoutMessage(e.target.value);
                      }}
                      placeholder="Out Message"
                    ></input>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {data.c && (
        <div className="card">
          <div className="card-header">
            <h3 className="parkoBlue">Update Admins </h3>
            <div className="income-data d-flex align-items-center justify-content-between  mb-xl-0 mb-3 ">
              <Button
                className="me-2"
                onClick={UpdateAdmin}
                variant="outline-primary"
              >
                {" "}
                <i class=" me-1 fa fa-plus"></i>Update Admins{" "}
              </Button>
              <Button
                onClick={cancelButton}
                className="me-2"
                style={{ marginRight: "10px" }}
                variant="outline-primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}

      {data.c && (
        <div className="card">
          <div className="card-body">
            <div className="search-results">
              <form>
                <div className="row">
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black" htmlFor="form3Example3">
                      <strong>Batch</strong>
                    </label>
                    <Select
                      id="form3Example3"
                      options={options}
                      value={editedUser.selectedOptions || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          selectedOptions: e.target.value,
                        })
                      }
                      placeholder="Search Trolleys..."
                      isMulti
                      styles={customStyles}
                    />
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>In Date</strong>
                    </label>
                    <input
                      type="date"
                      className="form-control"

                      value={editedUser.InDate || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          InDate: e.target.value,
                        })
                      }
                      placeholder="In Date"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>In Message</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"

                      value={editedUser.inMessage || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          inMessage: e.target.value,
                        })
                      }
                      placeholder="In Message"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>Type Of Maintence</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"

                      value={editedUser.typeOfMaintence || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          typeOfMaintence: e.target.value,
                        })
                      }
                      placeholder="Type Of Maintence"
                    ></input>
                  </div>

                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>Out Date</strong>
                    </label>
                    <input
                      type="date"
                      className="form-control"

                      value={editedUser.outDate || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          outDate: e.target.value,
                        })
                      }
                      placeholder="Out Date"
                    ></input>
                  </div>
                  <div className="mb-3 mt-2 col-md-3">
                    <label className="mb-1 text-black">
                      <strong>Out Message</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editedUser.outMessage || ""}
                      onChange={(e) =>
                        setEditedUser({
                          ...editedUser,
                          outMessage: e.target.value,
                        })
                      }
                      placeholder="Out Message"
                    ></input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {data.a && (
        <div className="card">
          <div className="card-body">
            <DataTable
              columns={columns}
              data={AllMaintenceDatas}
              customStyles={tableCustomStyles}
              pagination
              // expandableRowsComponent={expandableWinningPriceTable}
              // expandableRows
              // fixedHeader
              // selectableRowsHighlight
            />
          </div>
        </div>
      )}
    </div>
  );
};

// value={editedUser.username || ""}

// onChange={(e) =>
//   setEditedUser({
//     ...editedUser,
//     username: e.target.value,
//   })
// }
